const _assinaturaSignatario =
    [
        {
            valor: 1,
            descricao: "Pendente"
        },
        {
            valor: 2,
            descricao: "Visualizado"
        },
        {
            valor: 3,
            descricao: "Assinado"
        },
        {
            valor: 4,
            descricao: "Cancelado"
        },
        {
            valor: 5,
            descricao: "Ignorado pelo sistema"
        },
        {
            valor: 6,
            descricao: "Delegado"
        },
    ]

export default _assinaturaSignatario
