const _formaPagamento = [
    {
        valor: 1,
        descricao: "Escolher",
        icone: "account-question"
    },
    {
        valor: 2,
        descricao: "Boleto",
        icone: "barcode"
    },
    {
        valor: 3,
        descricao: "Cartão de Crédito",
        icone: "credit-card-outline"
    },
    {
        valor: 4,
        descricao: "PIX",
        icone: "qrcode"
    },
]

export default _formaPagamento
