import axios from "axios"

const api = axios.create({  
  // baseURL: "http://localhost:8000/api/v1/",
  baseURL: "https://api.icaro.fit/api/v1/",
    // process.env.NODE_ENV !== "production"
      // ? "http://localhost:8000/api/v1/"
      // : "http://icaro.fit:8080/api/v1/",
      // : "https://icaro.vps-kinghost.net/api/v1/",      
  headers: {
    Accept: "application/json",
    Content: "application/json",
    "content-type": "application/json",
  },
})

export default api