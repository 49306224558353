<template>
  <v-container fluid>
    <AppConfirma :dialogs="dialogs" @resposta="getResposta"></AppConfirma>
    <AppBar titulo="" destino="usuarios" />
    <v-toolbar color="orange" dense>
      <v-toolbar-title>Gerenciamento de Usuários</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <AppAlerta :snack="snack" />

    <v-spacer class="mt-4"></v-spacer>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-stepper v-model="step" vertical>
            <v-stepper-header>
              <v-stepper-step step="1" :complete="step > 1">Dados Pessoais</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" :complete="step > 2">Relacionamento</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3">Agenda</v-stepper-step>
              <v-stepper-step step="4">Contrato</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">                  
                <app-cadastro-usuario @dados-cadastro="receberDados"></app-cadastro-usuario>                             
                <v-btn color="primary" @click.native="step = 2" v-if="pessoais">Continuar</v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-switch
                  v-model="switch1"
                  inset      
                ></v-switch>
                <app-cadastro-aluno v-if="switch1==true"></app-cadastro-aluno>
                <app-cadastro-treinador v-if="switch1==false"></app-cadastro-treinador>
                <v-btn flat @click.native="step = 1">Voltar</v-btn>
                <v-btn color="primary" @click.native="step = 3">Continuar</v-btn>
              </v-stepper-content>
              <v-stepper-content step="3">              
                <app-agenda-aluno></app-agenda-aluno>                
                <v-btn flat @click.native="step = 2">Voltar</v-btn>
                <v-btn color="primary" @click.prevent="step=4">Continuar</v-btn>                
              </v-stepper-content>
              <v-stepper-content step="4">                            
                <v-btn flat @click.native="step = 3">Voltar</v-btn>
                <!-- <v-btn color="primary" @click.prevent="submit">Save</v-btn>                 -->
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
// import { required, email } from "vee-validate/dist/rules";
import _usuario from "../../services/usuario/usuario-servico.js";
import _utils from "../../utils/utils.js";
import AppAlerta from "../../components/AppAlerta.vue";
import AppBar from "../../components/AppBar.vue";
import AppConfirma from "../../components/AppConfirma.vue";
import AppCadastroUsuario from "../AppCadastroUsuario.vue";
import AppCadastroAluno from "../AppCadastroAluno.vue"
import AppCadastroTreinador from "../AppCadastroTreinador.vue"
import AppAgendaAluno from "../../view/Alunos/AppAgendaAluno.vue"
// import AppAgendaTreinador from "../../view/Treinadores/AppAgenda.vue"

// Add the required rule
// extend("required", required);

// Add the email rule
// extend("email", email);

export default {
  components: {
    // ValidationObserver,
    // ValidationProvider,
    AppBar,
    AppConfirma,
    AppAlerta,
    AppCadastroUsuario,
    AppCadastroAluno,
    AppCadastroTreinador,
    AppAgendaAluno,
    // AppAgendaTreinador,
  },

  data() {
    return {
      dialogs: {
        dialog: false,
      },
      snack: {
        snackbar: false,
        texto: "",
        cor: "",
      },
      resposta: "",
      id: 0,
      nome: "",
      login: "",
      senha: "letsmove",
      email: "",
      telefone: "",
      nascimento: "",
      cpf: "",
      rg: "",
      sexo: "",
      endereco: "",
      instagram: "",
      foto: "",
      usuario: [],
      isDisabled: true,
      e1: 1,
      switch1: true,
      step:1,
      pessoais: false
    };
  },

  computed: {
    _telefone() {
      return this.telefone.replace(/\D/g, "");
    },
    _cpf() {
      return this.cpf.replace(/\D/g, "");
    },
  },

  mounted() {
    if (this.$route.params.id != null) {
      this.buscar(this.$route.params.id);
      this.id = this.$route.params.id;
    }
  },

  methods: {
    buscar(id) {
      _usuario.buscarUsuario(id).then((response) => {
        console.log(response);
        this.usuario = response;
        this.nome = this.usuario.nome;
        if (this.usuario.nascimento != null)
          this.nascimento = _utils.formatarDataTela(this.usuario.nascimento);
        if (this.usuario.sexo == "M") {
          this.sexo = "Masculino";
        } else {
          this.sexo = "Feminino";
        }
        if (this.usuario.cpf != null) this.cpf = this.usuario.cpf;
        this.rg = this.usuario.rg;
        this.email = this.usuario.email;
        if (this.usuario.telefone != null)
          this.telefone = this.usuario.telefone;
        if (this.usuario.endereco != null)
          this.endereco = this.usuario.endereco;
        this.instagram = this.usuario.instagram;
        if (this.usuario.relacionamento == null) this.isDisabled = false;
      });
    },
    salvar() {
      this.usuario = {
        nome: this.nome,
        login: this._cpf,
        senha: this.senha,
        email: this.email,
        telefone: this._telefone,
        nascimento: _utils.formatarData(this.nascimento),
        cpf: this._cpf,
        rg: this.rg,
        sexo: this.sexo.substring(0, 1),
        endereco: this.endereco,
        instagram: this.instagram,
        foto: this.foto,
        sistema: 1,
      };

      if (this.id == 0) {
        this.salvarUsuario();
      } else {
        this.atualizar();
      }
    },

    atualizar() {
      _usuario.atualizarUsuario(this.id, this.usuario).then(() => {
        this.snack.texto = "Usuário atualizado com sucesso!";
        this.snack.cor = "success";
        this.snack.snackbar = true;
        setTimeout(() => {
          this.$router.push({ name: "usuarios" });
        }, 1000);
      });
    },
    salvarUsuario() {
      _usuario.cadastrarUsuario(this.usuario).then(() => {
        this.snack.texto = "Usuário cadastrado com sucesso!";
        this.snack.snackbar = true;
        setTimeout(() => {
          this.$router.push({ name: "usuarios" });
        }, 1000);
      });
    },
    excluir() {
      this.dialogs.dialog = true;
      this.dialogs.titulo = "Excluir Aluno";
      this.dialogs.mensagem =
        "Deseja excluir o usuário " + this.nome.toUpperCase() + "?";
    },
    getResposta(value) {
      this.resposta = value;

      if (this.resposta == true) {
        _usuario.deletarUsuario(this.id).then(() => {
          this.snack.texto = "Usuário excluído com sucesso!";
          this.snack.snackbar = true;
          setTimeout(() => {
            this.$router.push({ name: "usuarios" });
          }, 1000);
        });
      }
    },
    cancelar() {
      this.$router.replace({ name: "usuarios" });
    },
    reset() {
      requestAnimationFrame(() => {
        (this.nome = ""), this.$refs.observer.reset();
      });
    },

    limpaForm() {
      (this.nome = null),
        (this.login = ""),
        (this.senha = ""),
        (this.email = ""),
        (this.telefone = ""),
        (this.nascimento = ""),
        (this.cpf = ""),
        (this.rg = ""),
        (this.sexo = ""),
        (this.endereco = ""),
        (this.instagram = ""),
        (this.foto = "");
    },

    receberDados(dados) {
      // Faça algo com os dados recebidos do componente filho
      //console.log('Dados recebidos do componente filho:', dados);
      this.pessoais = dados;
    }
  },
};
</script>

<style>
.v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset) .v-input--switch__thumb {
  color: purple
}

.theme--light.v-input--switch .v-input--switch__track {
  color: purple
}
</style>