<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5"> Lista Dre </v-list-item-title>
              <!-- <v-list-item-subtitle>Informações revelantes</v-list-item-subtitle> -->
            </v-list-item-content>
            <v-btn color="primary" @click="dialogo = true">
                Novo <v-icon class="ml-2">mdi-plus</v-icon>
            </v-btn>
          </v-list-item>
          <v-divider class="cor-divisor mx-4" />
          <v-card-text>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              clearable
              label="consultar"
              single-line
              outlined
              dense
              hide-details
            />
            <v-data-table
              :headers="cabecalhoTabela"
              :items="itensTabela"
              :page.sync="pagina"
              :items-per-page="itensPorPagina"
              hide-default-footer
              :search="search"
              @page-count="qtPagina = $event"
            >             
              <template #[`item.acao`]="{ item }">
                <v-btn icon :to="`/gestao-financeira/${item.id}`"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn
                >
              </template>
            </v-data-table>
            <v-row class="pt-2">
              <v-col cols="10" class="text-center">
                <v-pagination
                  v-model="pagina"
                  :length="qtPagina"
                  color="light-blue lighten-2"
                />
              </v-col>
              <v-col cols="2" class="text-end">
                <v-text-field
                  :value="itensPorPagina"
                  label="Itens por página"
                  type="number"
                  min="-1"
                  :max="itensTabela.length"
                  @input="itensPorPagina = parseInt($event, 10)"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <app-modal-dre v-model="dialogo"/>
  </v-container>
</template>

<script>
import AppModalDre from "./AppModalDre.vue"

import _dre from "../../services/financeiro/dre.js";

export default {
  components: {AppModalDre},
  data() {
    return {
      search: "",

      pagina: 1,
      qtPagina: 0,
      itensTabela: [],
      itensPorPagina: 20,
      cabecalhoTabela: [
        { text: "Ano", value: "ano", align: "center" },
        { text: "Mês", value: "mes",  align: "center" },
        { text: "Lista Lançamentos", value: "acao", align: "center", sortable: false },
      ],
      dialogo: false, 
    };
  },
  mounted() {    
    this.$root.$refs.ListaFinanceiro = this
    this.listar();
  },
  methods: {
    async listar() {
      await _dre.listar().then((resultado) => {
        this.itensTabela = resultado;
      });
    },
  },
};
</script>
