<template>
    <ValidationObserver ref="form" #default="{ handleSubmit }">
      <v-form
        @keyup.enter="handleSubmit(salvar)"
        @submit.prevent="handleSubmit(salvar)"
      >
        <v-container>
          <v-row>
            <h1 class="font-weight-medium">Cobranças</h1>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Usuário"
                v-model="usuario"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-menu
                v-model="menuDatePickerI"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <ValidationProvider #default="{ errors }" name="Data Inicial">
                    <v-text-field
                      v-model="data_inicial"
                      label="Data Inicial"
                      prepend-inner-icon="mdi-calendar"
                      :error-messages="errors[0]"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="dataInicial"
                  :active-picker.sync="ativarDatePicker"
                  min="1900-01-01"
                  locale="pt-BR"
                />
              </v-menu>
            </v-col>
            <v-col cols="6" md="4">
              <v-menu
                v-model="menuDatePickerF"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <ValidationProvider #default="{ errors }" name="Data final">
                    <v-text-field
                      v-model="data_final"
                      label="Data final"
                      prepend-inner-icon="mdi-calendar"
                      :error-messages="errors[0]"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="dataFinal"
                  :active-picker.sync="ativarDatePicker"
                  min="1900-01-01"
                  locale="pt-BR"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col cols="12" md="3">
              <v-btn
                color="grey lighten-3"
                height="55"
                width="100%"
                @click="dialogConfiguracoes = true"
                ><v-icon>mdi-cogs</v-icon>Configurações Boleto</v-btn
              >
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                color="primary"
                height="55"
                width="100%"
                @click="listarCobrancas()"
                ><v-icon>mdi-magnify</v-icon>Pesquisar</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="cobrancasBanco.length > 0">
            <v-col cols="12">
              <v-card color="#FBFBFB" elevation="0">
                <v-card-title class="text-h4 font-weight-medium">
                  <v-icon class="mr-2 text-h4">mdi-view-list</v-icon>
                  {{ cardSelecionado }}
                </v-card-title>
                <v-card-text class="">
                  <tabela-cobrancas :cobrancas="cobrancas"></tabela-cobrancas>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <configuracao-boletos
            v-model="dialogConfiguracoes"
            @fechar="fecharModalConfiguracoes"
        />
        </v-container>
      </v-form>
    </ValidationObserver>
  </template>
    
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  
  import _cobrancas from "../../services/cobranca/cobranca.js"
  
  import TabelaCobrancas from "./TabelaCobrancas.vue";
  import ConfiguracaoBoletos from '../../view/Cobrancas/ConfiguracaoBoletos.vue';
  
  import util from "@/utils/utils.js";
  
  export default {
    components: {
     TabelaCobrancas,
     ConfiguracaoBoletos,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        cobrancasBanco: [],
        cobrancas: [],
  
        dialogConfiguracoes: false,

        usuario: null,
        data_inicial: null,
        data_final: null,
  
        dataInicial: null,
        menuDatePickerI: false,
  
        dataFinal: null,
        menuDatePickerF: false,
  
        ativarDatePicker: null,
  
        cardSelecionado: null,  
      };
    },
  
    mounted() {
      // Criar uma nova data
      let dataAtual = new Date();
  
      // Diminuir 30 dias da data atual
      dataAtual.setDate(1);
  
      this.data_final = util.converterFormatoPtBr(
        new Date(
          dataAtual.getFullYear(),
          dataAtual.getMonth() + 1,
          0
        ).toISOString()
      );
      this.data_inicial = util.converterFormatoPtBr(dataAtual.toISOString());
  
      this.listarCobrancas();
    },
    watch: {
      dataInicial() {
        this.data_inicial = util.converterFormatoPtBr(this.dataInicial);
      },
      dataFinal() {
        this.data_final = util.converterFormatoPtBr(this.dataFinal);
      },
    },
    methods: {
      fecharModalConfiguracoes(){
        this.dialogConfiguracoes = false;
      },
      
      listarCobrancas() {
        var filtros = {
          usuario: this.usuario,
          vencimento_de: util.formatarData(this.data_inicial),
          vencimento_ate: util.formatarData(this.data_final),
        };
        _cobrancas.listar(filtros).then((response) => {
          this.cobrancasBanco = response;
          this.popularTabela();
        });
      },
  
      popularTabela(selecionado = "Todas") {
        this.cardSelecionado = selecionado;
        this.cobrancas = this.cobrancasBanco;
      },
    },
  };
  </script>