import Vue from 'vue'
import App from './App.vue'
import router from './routes/router'
import vuetify from './plugins/vuetify'
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate"
import ptBr from "vee-validate/dist/locale/pt_BR.json"
import * as rules from "vee-validate/dist/rules"
import VMask from 'v-mask'
import VideoBackground from 'vue-responsive-video-background-player'
import money from  'vuejs-money'


Vue.use(VMask)
Vue.use(money)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize("pt_BR", ptBr)

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)
Vue.component('video-background', VideoBackground);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')