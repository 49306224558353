<template>
  <v-container fluid>
    <v-toolbar color="orange" dense>
      <v-toolbar-title>Cadastro Usuário | 1 - Dados Pessoais</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <AppConfirma :dialogs="dialogs" @resposta="getResposta"></AppConfirma>    
    <AppAlerta :color="snackbarColor" :text="snackbarText" v-if="show"/>

    <v-spacer class="mt-4"></v-spacer>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <ValidationObserver ref="form" #default="{ handleSubmit }">
            <v-form
              @keyup.enter="handleSubmit(salvar)"
              @submit.prevent="handleSubmit(salvar)"
              @reset.prevent="reset"
            >
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="cpf"
                      label="CPF"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'CPF é obrigatório']"
                      outlined
                      required
                      v-mask="'###.###.###-##'"
                      :disabled="id !=0 ? true : false"
                    >
                    </v-text-field>
                  </v-col>                    
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="nome"
                    >
                      <v-text-field
                        v-model="nome"
                        label="Nome"
                        color="orange"
                        :error-messages="errors[0]"
                        outlined
                        :disabled="desabilitado"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="nascimento"
                      v-mask="'##/##/####'"
                    >
                      <v-text-field
                        v-model="nascimento"
                        label="Data Nascimento"
                        color="#FF562B"
                        :error-messages="errors[0]"
                        outlined
                        :disabled="desabilitado"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>                  
                  <v-col cols="12" sm="3">
                    <v-select
                      v-model="sexo"
                      :items="['Masculino', 'Feminino']"
                      label="Sexo"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Sexo é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    />
                  </v-col>                  
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="rg"
                      label="RG"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'RG é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <ValidationProvider
                      rules="required|email"
                      mode="passive"
                      name="Email"
                    >
                      <v-text-field
                        v-model="email"
                        label="E-mail"
                        color="#FF562B"
                        :rules="[(v) => !!v || 'E-mail é obrigatório']"
                        outlined
                        :disabled="desabilitado"
                        @blur="emailtrim()"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="3" xl="6">
                    <v-text-field
                      v-model="telefone"
                      label="Telefone"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Telefone é obrigatório']"
                      outlined
                      required
                      v-mask="'(##)# ####-####'"
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="2" xl="2">
                    <v-text-field
                      v-model="cep"
                      label="Cep"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Cep é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" xl="4">
                    <v-text-field
                      v-model="logradouro"
                      label="Logradouro"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Logradouro é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" xl="2">
                    <v-text-field
                      v-model="numero"
                      label="Número"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Número é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" xl="2">
                    <v-text-field
                      v-model="complemento"
                      label="Complemento"
                      color="#FF562B"
                      outlined
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" xl="2">
                    <v-text-field
                      v-model="bairro"
                      label="Bairro"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Bairro é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" md="4" xl="6">
                    <v-text-field
                      v-model="instagram"
                      label="Instagram"
                      color="#FF562B"
                      outlined
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col> -->
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-row>
                  <v-col col="12" sm="4" md="4" xl="4">
                    <v-btn color="grey" width="100%" dark @click="cancelar()">Cancelar</v-btn>
                  </v-col>
                  <v-col col="12" sm="4" md="4" xl="4">
                    <v-btn
                      color="red"
                      width="100%"
                      dark
                      @click="excluir()"
                      :disabled="isDisabled"
                      >Excluir <v-icon class="ml-2">mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col col="12" sm="4" md="4" xl="4">
                    <v-btn color="#FF562B" width="100%" dark type="submit"
                      >Salvar<v-icon class="ml-2">mdi-content-save</v-icon></v-btn
                    >
                  </v-col>                                    
                    <v-col col="12" sm="12" md="12" xl="12">
                    <v-btn color="#FF562B" width="100%" dark @click="avancar()" v-if="id>0">
                      Dados do Aluno<v-icon class="ml-2">mdi-arrow-right-bold-circle-outline</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import _usuario from "../services/usuario/usuario-servico.js";
import _utils from "../utils/utils.js";
import AppAlerta from "../components/AppAlerta.vue";
import AppConfirma from "../components/AppConfirma.vue";

// Add the required rule
extend("required", required);

// Add the email rule
extend("email", email);

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AppConfirma,
    AppAlerta,
  },

  data() {
    return {
      dialogs: {
        dialog: false,
      },
      resposta: "",
      id: 0,
      nome: "",
      login: "",
      senha: "$2b$12$KPffG/xe/Gi1VW.E2e.1teSXp1jW8MJgDoBXzcbI577QIs4wY1jWS",
      email: "",
      telefone: "",
      nascimento: "",
      cpf: "",
      rg: "",
      sexo: "",
      endereco: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      // instagram: "",
      foto: "",
      usuario: [],
      isDisabled: true,
      show: false,
      snackbarColor: '',
      snackbarText: '',  
      tipo: '',
      desabilitado: true,
    };
  },

  computed: {
    _telefone() {
      return this.telefone.replace(/\D/g, "");
    },
    _cpf() {
      return this.cpf.replace(/\D/g, "");
    },
  },

  mounted() {
    if (this.$route.params.id != null) {
      this.buscar(this.$route.params.id);
      this.id = this.$route.params.id;
      this.tipo = this.$route.params.tipo;
    }
    else if (this.$route.params.tipo != null) {
      this.tipo = this.$route.params.tipo;
    }
    // else{
    //   this.$router.push({ name: "alunos" });
    // }
  },

  watch: {
        cpf: {
            // immediate: true,
            deep: true,
            async handler (alteracoes) {
              if (this.id == 0)
              {
                const valorSemPontos = alteracoes.replace(/\D/g, "") // Remove os pontos
                if (valorSemPontos.length === 11) {
                    // Verifica se o comprimento é igual a 11
                    if (this.validaCPF(valorSemPontos)) {                        
                    //if (this.proximo) {                        
                        //await this.verificarUsuario(valorSemPontos)
                        await this.buscarUsuario(valorSemPontos)
                    //}
                    } else {
                        //this.cpf.rules = "X"                    
                    // this.mostrarMensagem({
                    //     tipo: "error",
                    //     descricao: "CPF inválido.",
                    // })
                        alert("CPF inválido");
                    }
                }
                this.desabilitado = true;
              }
              else 
              {
                  this.desabilitado = false;
              }
            },
        },
    },

  methods: {
    emailtrim()
    {
      this.email = this.email.trim()
    },

    validarJson(jsonString) {
    try {
      JSON.parse(jsonString);
      return true;
      } catch (error) {
        return false;
      }
    },

    buscar(id) {
      _usuario.buscarUsuario(id).then((response) => {
        this.usuario = response;
        this.nome = this.usuario.nome;
        if (this.usuario.nascimento != null)
          this.nascimento = _utils.formatarDataTela(this.usuario.nascimento);
        if (this.usuario.sexo == "M") {
          this.sexo = "Masculino";
        } else {
          this.sexo = "Feminino";
        }
        if (this.usuario.cpf != null) this.cpf = this.usuario.cpf;
        this.rg = this.usuario.rg;
        this.email = this.usuario.email;
        if (this.usuario.telefone != null)
          this.telefone = this.usuario.telefone;
        if (this.usuario.endereco != null)
        {          
          if (this.validarJson(this.usuario.endereco))
          {
            this.endereco = JSON.parse(this.usuario.endereco);
            this.cep = this.endereco.cep
            this.logradouro = this.endereco.logradouro
            this.numero = this.endereco.numero
            this.complemento = this.endereco.complemento
            this.bairro = this.endereco.bairro 
          }
          else
          {              
            this.logradouro = this.usuario.endereco
          }
        }
        // this.instagram = this.usuario.instagram;
        if (this.usuario.relacionamento == null) this.isDisabled = false;
        // console.log(this.endereco)
      });
    },

    buscarUsuario(cpf) {
            _usuario.buscarUsuarioCpf(cpf).then((response) => {                
                // this.usuario = response;
                // //console.log(this.usuario);
                // this.rg = this.usuario.rg;
                // this.nome = this.usuario.nome;
                // if (this.usuario.nascimento != null)
                //     this.nascimento = _utils.formatarDataTela(this.usuario.nascimento);
                // if (this.usuario.sexo == "M") {
                //     this.sexo = "Masculino";
                // } else if (this.usuario.sexo == "F") {
                //     this.sexo = "Feminino";
                // }                
                // this.email = this.usuario.email;
                // if (this.usuario.telefone != null)
                //     this.telefone = this.usuario.telefone;
                // if (this.usuario.endereco != null)
                //     this.endereco = this.usuario.endereco;
                // // this.instagram = this.usuario.instagram;
                // // if (this.usuario.relacionamento == null) this.isDisabled = false;                
                if (response)
                {
                  this.desabilitado = true;
                  this.snackbarColor = 'success'; // ou 'error', 'info', etc.
                  this.snackbarText = 'Usuário já cadastrado!';
                  this.show = true;                  
                  setTimeout(() => {
                    this.show = false;                    
                    this.$router.push({name:'cadastro', params: {id: response.id, tipo: 'Aluno'}})                    
                    window.location.reload()
                  }, 3000);
                }
                else 
                  this.desabilitado = false;
            }).catch(() => 
              {
                this.desabilitado = false;
              }
            );
        },

    salvar() {
      this.endereco = {
        cep: this.cep,
        logradouro: this.logradouro,
        numero: this.numero,
        complemento: this.complemento,
        bairro: this.bairro
      };

      this.endereco = JSON.stringify(this.endereco);

      this.usuario = {
        nome: this.nome,
        login: this._cpf,
        senha: this.senha,
        email: this.email.trim(),
        telefone: this._telefone,
        nascimento: _utils.formatarData(this.nascimento),
        cpf: this._cpf,
        rg: this.rg,
        sexo: this.sexo.substring(0, 1),
        endereco: this.endereco,
        instagram: "",
        foto: this.foto,
        objetivo: '',
        precadastro: true,
        nivel_acesso: '',
        sistema: 1,
      };      

      if (this.id == 0) {
        this.salvarUsuario();
      } else {        
        this.atualizar();
      }
    },

    atualizar() {      
      _usuario.atualizarUsuario(this.id, this.usuario).then(() => {        
        this.snackbarColor = 'success'; // ou 'error', 'info', etc.
        this.snackbarText = 'Usuário atualizado com sucesso!';   
        this.show = true;
        setTimeout(() => {
            this.show = false;
          }, 3000);
      });
    },
    salvarUsuario() {
      _usuario.cadastrarUsuario(this.usuario).then((response) => {        
        console.log(response)
        this.id = response.id
        this.snackbarColor = 'success'; // ou 'error', 'info', etc.
        this.snackbarText = 'Usuário cadastrado com sucesso!';
        this.show = true;
        setTimeout(() => {
          this.show = false;
        }, 3000);
      });      
    },
    excluir() {
      this.dialogs.dialog = true;
      this.dialogs.titulo = "Excluir Aluno";
      this.dialogs.mensagem =
        "Deseja excluir o usuário " + this.nome.toUpperCase() + "?";
    },
    getResposta(value) {
      this.resposta = value;

      if (this.resposta == true) {
        _usuario.deletarUsuario(this.id).then(() => {
          this.snackbarColor = 'success';
          this.snackbarText = "Usuário excluído com sucesso!";
          this.show = true;
          setTimeout(() => {
          this.show = false;
        }, 3000);
        });
      }
    },
    cancelar() {
      this.$router.replace({ name: "usuarios" });
    },
    reset() {
      requestAnimationFrame(() => {
        (this.nome = ""), this.$refs.observer.reset();
      });
    },

    limpaForm() {
      (this.nome = null),
        (this.login = ""),
        (this.senha = ""),
        (this.email = ""),
        (this.telefone = ""),
        (this.nascimento = ""),
        (this.cpf = ""),
        (this.rg = ""),
        (this.sexo = ""),
        (this.endereco = ""),
        // (this.instagram = ""),
        (this.foto = "");
    },

    avancar(){
      if (this.tipo == 'Aluno')
        this.$router.push({name:'cadastroaluno', params: {usuario: this.usuario}})
      else if (this.tipo == 'Treinador')
        this.$router.push({name:'cadastrotreinador', params: {id: this.id}})
      else
        this.$router.push({ name: "alunos"});
    },

    verificarCPF (campo) {
            const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
            return cpfRegex.test(campo)
        },

        validaCPF (cpf) {
            var Soma = 0
            var Resto

            var strCPF = String(cpf).replace(/[^\d]/g, "")

            if (strCPF.length !== 11) { return false }

            if ([
                "00000000000",
                "11111111111",
                "22222222222",
                "33333333333",
                "44444444444",
                "55555555555",
                "66666666666",
                "77777777777",
                "88888888888",
                "99999999999",
            ].indexOf(strCPF) !== -1) { return false }

            for (let i = 1; i <= 9; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i) }

            Resto = (Soma * 10) % 11

            if ((Resto === 10) || (Resto === 11)) { Resto = 0 }

            if (Resto !== parseInt(strCPF.substring(9, 10))) { return false }

            Soma = 0

            for (let i = 1; i <= 10; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i) }

            Resto = (Soma * 10) % 11

            if ((Resto === 10) || (Resto === 11)) { Resto = 0 }

            if (Resto !== parseInt(strCPF.substring(10, 11))) { return false }

            return true
        },
  },
};
</script>