/**
 * @typedef {Object} Dre
 * @property {Number} id
 * @property {Date} criacao
 * @property {Date} atualizacao
 * @property {Date} exclusao
 * @property {Number} mes
 * @property {Number} ano
 * @property {Number} saldo_inicial
 * @property {Number} lucro
 */
export default class Dre {
    constructor(
      id,
      criacao,
      atualizacao,
      exclusao,
      mes,
      ano,
      saldo_inicial,
      lucro
    ) {
      this.id = id
      this.criacao= criacao
      this.atualizacao = atualizacao
      this.exclusao = exclusao
      this.mes = mes
      this.ano = ano
      this.saldo_inicial = saldo_inicial
      this.lucro = lucro
    }
  }
