import api from "../api/icaro"

const relatorios = {

  /**
     *
     * @returns {Promise} response.data ou erro
     */
  listarEntradas(filtros) {
    return new Promise((resolve, reject) => {
      api
        .post(`/relatorios/entradas/`, filtros)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarEntradasTreinadores(filtros) {
    return new Promise((resolve, reject) => {
      api
        .post(`/relatorios/entradas/treinadores/`, filtros)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
     *
     * @returns {Promise} response.data ou erro
     */
  listarSaidas(filtros) {
    return new Promise((resolve, reject) => {
      api
        .post(`/relatorios/saidas/`, filtros)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarSaidasTreinadores(filtros) {
    return new Promise((resolve, reject) => {
      api
        .post(`/relatorios/saidas/treinadores/`, filtros)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarEstatisticas(filtros) {
    return new Promise((resolve, reject) => {
      api
        .post(`/relatorios/estatisticas`, filtros)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

}

export default relatorios