<template>
    <v-hover>
        <template>
            <v-card
                v-if="possuiAcao"
                class="mx-auto mt-4"
                :style="`background: ${fundo};`"
                @click="acao"
            >
            {{ cor }}
                <v-card-title>
                    <v-icon
                        class="icone-card elevation-6 rounded"
                        :style="`background: ${cor}`"
                        large
                        left>
                        mdi-{{ icone }}
                    </v-icon>
                    <span class="text-h5 font-weight-light">{{ titulo }}</span>
                    <v-icon
                        v-if="possuiAcao"
                        class="float-right posicao-icone">
                        mdi-information
                    </v-icon
                    >
                </v-card-title>
                <v-card-text class="text-h4 font-weight-bold text-right">
                    {{ total }}
                </v-card-text>
                <!-- <v-fade-transition>
          <v-overlay v-if="hover" absolute color="#036358">
            <v-icon large>mdi mdi-eye</v-icon>
          </v-overlay>
        </v-fade-transition> -->
            </v-card>
            <v-card
                v-else
                class="mx-auto mt-4"                
                :style="`background-color: ${fundo};`">
                <v-card-title>
                    <v-icon
                        large
                        left
                        class="icone-card elevation-6 rounded"
                        :style="`background: ${cor}`">
                        mdi-{{ icone }}
                    </v-icon>
                    <span class="text-h5 font-weight-light">{{ titulo }}</span>
                </v-card-title>
                <v-card-text class="text-h4 font-weight-bold text-right">
                    {{ total }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="acessar(caminho)" icon>
                        <v-icon>mdi-arrow-right-circle-outline</v-icon>
                    </v-btn></v-card-actions>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
export default {
  props: {
    possuiAcao: { required: false, default: true, type: Boolean },
    acao: Function,
    icone: String,
    titulo: String,
    total: Number,
    cor: String,
    fundo: String,
    caminho: String,
  },
  
  methods: {
    acessar(rota){      
            this.$router.push({ name: rota })
        },
    }
}
</script>

<style scoped>
.posicao-icone {
  top: 8%;
  right: 2%;
  position: absolute;
}
.icone-card {
  padding: 20px;
  color: #ffffff;
}
</style>    