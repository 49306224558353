import api from "../../api/ferramentas"

const icaro = {
    /**
       *
       * @returns {Promise} response.data ou erro
       */
    gerar(json) {
        const parametro = { jsonpar: JSON.stringify(json) };
        console.log(parametro);
        return new Promise((resolve, reject) => {
            api
                .post("/contrato/gerar-assinatura/", parametro)
                // .get("/contrato/gerar-assinatura/", {
                //     params: {
                //         json: JSON.stringify(json),
                //     }
                // })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
     /**
       *
       * @returns {Promise} response.data ou erro
       */
     buscarContratoAluno(id) {
        return new Promise((resolve, reject) => {
            api
                .get(`/contrato/buscar/${id}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
    /**
       *
       * @returns {Promise} response.data ou erro
       */
    listarAssinaturas(id) {
        return new Promise((resolve, reject) => {
            api
                .get(`/contrato/listar-assinaturas/${id}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
}

export default icaro