<template>
   <v-app prd='icaro'>
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md3>
                  <!-- <v-img src="../assets/logo_branco_preto.png" width="300px" class="mx-auto" style="margin: 20px" /> -->
                  <v-card class="elevation-12 pa-4 text-center text-no-wrap rounded-lg" height="auto">
                     <div class="d-flex flex-column justify-space-between align-center">
                        <v-img src="../assets/icaro.png" max-width="120px" contain />
                     </div>
                     <!-- <v-toolbar dark color="#211928">
                        <v-toolbar-title>{{isRegister ? stateObj.register.name : stateObj.login.name}}</v-toolbar-title>
                     </v-toolbar> -->
                     <v-spacer class="mt-4"></v-spacer>
                     <v-card-text>
                        <form ref="form" @submit.prevent="isRegister ? register() : login()">
                           <v-text-field v-model="usuario" name="usuario" label="Usuário" type="text"
                              prepend-inner-icon="mdi-account-outline" placeholder="Usuário" color="#FF562B"
                              required></v-text-field>
                           <v-spacer class="mt-4"></v-spacer>
                           <v-text-field v-model="senha" name="senha" label="Senha"
                              prepend-inner-icon="mdi-lock-outline" placeholder="Senha" color="#FF562B"
                              required :append-icon="exibir ? 'mdi-eye' : 'mdi-eye-off'" :type="exibir ? 'text' : 'password'" @click:append="exibir = !exibir"></v-text-field>
                           <v-spacer class="mt-4"></v-spacer>
                           <v-text-field v-if="isRegister" v-model="confirmPassword" name="confirmPassword"
                              label="Confirme Password" prepend-inner-icon="mdi-lock-outline" type="password" placeholder="confirme password"
                              required></v-text-field>
                           <v-alert v-if="errorMessage !== ''" type="error" dismissible> {{ errorMessage }}</v-alert>
                           <v-btn type="submit" class="mt-4" color="#FF562B" value="log in" width="100%" dark>{{ isRegister
                              ? stateObj.register.name : stateObj.login.name }}</v-btn>
                           <!-- <div class="grey--text mt-4 c-pointer" v-on:click="isRegister = !isRegister;">
                              {{toggleMessage}}
                           </div> -->
                        </form>
                     </v-card-text>
                  </v-card>

               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
import _usuarios from '../services/usuario/usuario-servico.js'
import LoginRequest from '../models/login-request.js'
export default {
   name: "App",

   data() {
      return {
         usuario: "",
         senha: "",
         dados: [],
         LoginRequest: new LoginRequest(),
         confirmPassword: "",
         isRegister: false,
         errorMessage: "",
         stateObj: {
            register: {
               name: 'Registrar',
               message: 'Já possui uma conta? login.'
            },
            login: {
               name: 'Login',
               message: 'Registrar'
            }
         },
         exibir: false,
      };
   },
   mounted() {
      if (localStorage.dados) this.dados = localStorage.dados;
   },
   methods: {
      async login() {
         // this.loginRequest.usuario = this.usuario;
         // this.loginRequest.senha = this.senha;
         //console.log(this.loginRequest);
         if (this.usuario.toLowerCase() == "roda" && this.senha.toLowerCase() == "saude") {
            this.$router.replace({ name: "rodasaude" });
         }

         const formData = new FormData();
         formData.append('username', this.usuario.toLowerCase());
         formData.append('password', this.senha);
         _usuarios.login(formData).then(response => {
            if (response.status != '') {
               this.dados = response
               localStorage.dados = JSON.stringify(this.dados);
               if (this.dados.admin == true)
                  this.$router.replace({ name: "dashboard", params: { username: this.dados['nome'], img: this.dados['foto'] } });
               else if (this.dados.relacionamento == "Treinador")
                  this.$router.replace({ name: "treinadorinicial" });
               //this.$router.push('/alunos');
            } else {
               this.errorMessage = response.message;
            }
         }).catch(() => {this.errorMessage = "Dados incorretos!!"})
         //const { username } = this;
         //this.$router.replace({ name: "dashboard", params: { username: username } });
      },
      register() {
         if (this.password == this.confirmPassword) {
            this.isRegister = false;
            this.errorMessage = "";
            this.$refs.form.reset();
         }
         else {
            this.errorMessage = "Senhas não conferem."
         }
      }
   },
   computed: {
      toggleMessage: function () {
         return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message
      }
   }
};
</script>

<style>
/* #app {
  background: url('../assets/background.jpg')
    no-repeat center center fixed !important;
  background-size: cover;
} */
.c-pointer{
   cursor: pointer;
}
</style>