import api from "../api/icaro"

const icaro = {  
  /**
   *
   * @returns {Promise} response.data ou erro
   */
  buscarAnamneseAluno(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/anamnese/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

    /**
   * @param {Object} anamnese
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
   cadastrarAnamnese(anamnese) {
    return new Promise((resolve, reject) => {
      api
        .post(`/alunos/anamnese/`, anamnese)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} anamnese
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  atualizarAnamnese(id, anamnese) {
    return new Promise((resolve, reject) => {
      api
        .put(`/alunos/anamnese/${id}`, anamnese)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  deletarAnamnese(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/alunos/anamnese/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  }
  
}

export default icaro