/**
 * @typedef {Object} ConfiguracaoNotaFiscal
 * @property {number} academia_id
 * @property {Date} data_referencia
 * @property {boolean} tomador_nota_reter_iss
 * @property {number} aliquota_iss
 * @property {number} aliquota_cofins
 * @property {number} aliquota_csll
 * @property {number} aliquota_inss
 * @property {number} aliquota_ir
 * @property {number} aliquota_pis
 */
export default class ConfiguracaoNotaFiscal {
    constructor(
        academia_id,
        dataReferencia,
        tomadorRetemIss,
        iss,
        cofins,
        csll,
        inss,
        ir,
        pis,
    ) {
        this.academia_id = academia_id
        this.data_referencia = dataReferencia
        this.tomador_nota_reter_iss = tomadorRetemIss
        this.aliquota_iss = iss
        this.aliquota_cofins = cofins
        this.aliquota_csll = csll
        this.aliquota_inss = inss
        this.aliquota_ir = ir
        this.aliquota_pis = pis
    }
}