<template>
    <v-container fluid>
        <v-toolbar color="orange" dense>
            <v-toolbar-title>Cadastro Usuário | 3 - Agenda do Aluno </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <AppConfirma :dialogs="dialogs" @resposta="getResposta"></AppConfirma>    
        <AppAlerta :snack="snack" />
        <v-spacer class="mt-4"></v-spacer>
        <v-row align="center" justify="center">
            <v-col col="12" md="12" xl="12" xs="12" sm="12">
                <v-card>
                    <ValidationObserver ref="form" #default="{ handleSubmit }">
                        <v-form @keyup.enter="handleSubmit(salvar)" 
                                    @submit.prevent="handleSubmit(salvar)">
                            <v-card-text>
                                <v-row>                                    
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="nome"
                                            label="Aluno"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <ValidationProvider
                                            #default="{ errors }"
                                            :rules="{ required: true }"
                                            name="treinador"
                                        >                       
                                            <v-select
                                            v-model="treinador"
                                            :items="treinadores"
                                            label="Treinador"
                                            :error-messages="errors[0]"
                                            item-text="nome"
                                            item-value="id"
                                            outlined
                                            :disabled="trinador_desativado"
                                            />
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6">
                                        <ValidationProvider 
                                            #default="{ errors }"
                                            :rules="{ required: true }"
                                            name="dia da semana">
                                            <v-select
                                                v-model="diasemana"
                                                :items="dias"
                                                label="Dia da semana"
                                                color="#FF562B"
                                                :error-messages="errors[0]"
                                                outlined>
                                            </v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <ValidationProvider 
                                            #default="{ errors }"
                                            :rules="{ required: true }"
                                            name="hora"
                                            v-mask="'##:##'">
                                            <v-text-field
                                                v-model="hora"
                                                label="Hora"
                                                color="#FF562B"
                                                :error-messages="errors[0]"
                                                outlined>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="12">
                                        <v-textarea
                                            v-model="observacao"
                                            label="Observação"
                                            color="#FF562B"
                                            outlined
                                            multiline
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>                        
                    <v-divider />
                    <v-card-actions>
                        <v-row>
                            <v-col col="12" md="4" xl="4" sm="4">
                                <v-btn @click="cancelar()" color="grey" width="100%" dark>Cancelar</v-btn>
                            </v-col>                            
                            <v-col col="12" md="4" xl="4" sm="4">
                                <v-btn color="red" width="100%" dark @click="excluir()" :disabled="isDisabled">Excluir</v-btn>
                            </v-col>
                            <v-col col="12" md="4" xl="4" sm="4">
                                <v-btn :disabled="SalvarDesativado" color="#FF562B" width="100%" type="submit" dark>Salvar</v-btn>
                            </v-col>                            
                            <v-col col="6" md="6" xl="6" sm="6">
                                <v-btn color="#FF562B" width="100%" dark @click="rota('cadastroaluno')">
                                <v-icon class="pr-2">mdi-arrow-left-bold-circle-outline</v-icon>Dados do Aluno</v-btn
                                >
                            </v-col>
                            <v-col col="6" md="6" xl="6" sm="6">
                                <v-btn color="#FF562B" width="100%" dark @click="rota('cadastrocontratoaluno')">
                                Contrato<v-icon class="ml-2">mdi-arrow-right-bold-circle-outline</v-icon></v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card-actions>
                    </v-form>                
                    </ValidationObserver>
                </v-card>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col col="12" md="12" xl="12" xs="12" sm="12">
                <v-card>                                        
                    <v-data-table
                        :headers="headers"
                        :items="agendas"
                        :items-per-page="10"
                        class="elevation-1"
                        >
                        <template v-slot:[`item.dia`]="{ item }">
                            <span>
                                {{ getDiaSemana(item.dia_semana) }}
                            </span>
                        </template>
                        <template v-slot:[`item.hora`]="{ item }">
                            <span>
                                {{ formataHora(item.horario) }}
                            </span>
                        </template>
                        <template v-slot:[`item.observacao`]="{ item }">
                            <v-tooltip top v-if="item.observacao" color="black">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    medium
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-note-text-outline
                                    </v-icon>
                                </template>
                                <span>{{ item.observacao }}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`item.editar`]="{ item }">
                            <v-icon
                            medium
                            class="mr-2"
                            @click="editarAgenda(item)"
                            >
                            mdi-pencil-outline
                            </v-icon>
                        </template>
                        <!-- <template v-slot:[`item.excluir`]="{ item }">
                            <v-icon
                            medium
                            style="align: center"
                            @click="excluirContrato(item.id)"
                            >
                            mdi-trash-can-outline
                            </v-icon>
                        </template>             -->
                    </v-data-table>                        
                </v-card>
            </v-col>
        </v-row>
    </v-container>    
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import AppConfirma from '../components/AppConfirma.vue'
import AppAlerta from '../components/AppAlerta.vue'
import _aluno from '../services/aluno/aluno-servico.js'
import _agenda from '../services/aluno/aluno-agenda-servico.js'
import _utils from '../utils/utils.js'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        AppConfirma,
        AppAlerta,
    },
    data () {
        return {
            dialogs: {
                dialog: false,
                titulo: ''
            },
            snack: { 
                snackbar: false,
                texto: '',
                cor: ''
            },
            id: 0,
            treinadores: [],
            aluno: [],
            agendas: [],
            nome: '',
            treinador: '',
            diasemana: '',
            hora: '',
            trinador_desativado: false,
            isDisabled: true,
            SalvarDesativado: true, 
            observacao: '',
            dias: [ {text: 'Segunda', value: 1},
                    {text: 'Terça', value: 2},
                    {text: 'Quarta', value: 3},
                    {text: 'Quinta', value: 4},
                    {text: 'Sexta', value: 5},
                    {text: 'Sábado', value: 6},
                    {text: 'Domingo', value: 7}
            ],
            headers: [
                { text: 'Código', value: 'id' },
                { text: 'Dia da Semana', align: 'start',value: 'dia' },
                { text: 'Hora', value: 'hora' },
                { text: 'Treinador', value: 'treinador'},
                { text: 'Produto', value: 'produto'},
                { text: 'Observação', value: 'observacao'},
                { text: 'Editar', value: 'editar', align: 'center', sortable: false },                
            ],
        }
    },

    mounted() {
        if (this.$route.params.aluno != null)
        {               
            this.aluno = this.$route.params.aluno
            this.nome = this.aluno.nome            
            _agenda.buscarProdutosTreinadores(this.aluno.usuario_id).then((response) => {
                response.forEach((treinador) => {
                    this.treinadores.push({
                    id: treinador.id,
                    nome: treinador.nome,
                    });
                });
            });
            if(this.treinadores.length == 0){
                this.treinadores.push({
                    id: this.aluno.treinador_id,
                    nome: this.aluno.treinador,
                });
            }      
            this.listarAgenda(this.aluno.usuario_id)
            if (this.id == 0)
            {
                this.SalvarDesativado = false
            }
        }
        else {
            this.$router.push({ name: 'alunos' })
        }
    },

    methods: {
        buscar(id)
        {
            _aluno.buscarAluno(id)
                .then((response) => {
                    this.aluno = response                    
                    this.nome = this.aluno.nome
                    this.treinador = this.aluno.treinador_id
                    this.observacao = this.aluno.observacao
                })
        },
        listarAgenda(id) {            
            _agenda.buscarAgenda(id).then(response => {
                this.agendas = response                
            })            
        },
        salvar()
        {
            this.agenda = {
                aluno_id: this.aluno.id,
                dia_semana: this.diasemana,
                horario: this.hora,
                treinador_id: this.aluno.treinador_id,
                observacao: this.observacao == null ? "" : this.observacao,
            }

            if (this.id == 0) {
                this.salvarAgenda()
            }
            else {
                this.atualizar()
            }
        },
        salvarAgenda() {
            _agenda.salvarAgenda(this.agenda)
                .then(() => {
                    this.snack.texto = 'Agenda salva com sucesso!'
                    this.snack.snackbar = true
                    setTimeout(() => {  this.listarAgenda(this.aluno.usuario_id) }, 500);
                    this.limparCampos()
                })
                .catch(() => {
                    this.dialogs.dialog = false
                    this.snack.snackbar = true
                    this.snack.texto = 'Erro ao salvar agenda!'
                    this.snack.cor = 'error'
                })
        },
        atualizar() {
            _agenda.atualizarAgenda(this.id, this.agenda)
                .then(() => {
                    this.dialogs.dialog = false
                    this.snack.snackbar = true
                    this.snack.texto = 'Agenda atualizada com sucesso!'
                    this.snack.cor = 'success'
                    setTimeout(() => {  this.listarAgenda(this.aluno.usuario_id) }, 500);
                    this.limparCampos()
                })
                .catch(() => {
                    this.dialogs.dialog = false
                    this.snack.snackbar = true
                    this.snack.texto = 'Erro ao atualizar agenda!'
                    this.snack.cor = 'error'
                })
        },
        editarAgenda(agenda){
            this.id = agenda.id
            this.diasemana = agenda.dia_semana
            this.hora = agenda.horario
            this.observacao = agenda.observacao
            this.treinador = agenda.treinador_id
            // verificar se não tem aulas para habilitar o excluir
            this.isDisabled = false
            this.trinador_desativado = true;
        },                
        excluir()
        {
            this.dialogs.dialog = true;
            this.dialogs.titulo = 'Excluir Agenda';
            this.dialogs.mensagem = 'Deseja realmente excluir a agenda?';            
        },
        getResposta (resposta) {
            if (resposta) {
                _agenda.excluirAgenda(this.id)
                    .then(() => {
                        this.dialogs.dialog = false
                        this.snack.snackbar = true
                        this.snack.texto = 'Agenda excluída com sucesso!'
                        this.snack.cor = 'success'
                        this.listarAgenda(this.aluno.usuario_id)
                        this.limparCampos()
                    })
                    .catch(() => {
                        this.dialogs.dialog = false
                        this.snack.snackbar = true
                        this.snack.texto = 'Erro ao excluir agenda!'
                        this.snack.cor = 'error'
                    })
            }
            else {
                this.dialogs.dialog = false
            }
        },
        getDiaSemana(dia){
            return _utils.diaSemana(dia)
        },
        formataHora(hora){
            return _utils.formataHora(hora)
        },
        limparCampos(){
            this.id = 0            
            this.diasemana = ""
            this.hora = ""
            this.observacao = ""
            this.treinador = ""
            this.trinador_desativado = false;
            this.$refs.form.reset()
        },  
        cancelar(){
            //this.rota('alunos')
            this.limparCampos();
        },
        rota(rota){      
            if (rota == 'cadastroaluno' || rota == 'cadastrocontratoaluno')
                this.$router.push({name:rota, params: {aluno: this.aluno, tipo: 'Aluno'}})    
            else
                this.$router.push({name:rota})    
        }
    }
}
</script>