<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Descrição</th>
            <th class="text-center">Valor</th>
            <th class="text-center">Deletar</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in valores.filter((x) => x.tipo === tipo)"
            :key="item.id"
          >
            <td class="text-left">{{ item.descricao }}</td>
            <td>{{ util.formatarDinheiro(item.valor) }}</td>
            <td>
              <v-btn icon @click="deletar(item.id)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </td>
          </tr>
          <tr>
            <td class="text-left text-body-1 font-weight-bold">Total</td>
            <td class="text-body-1 font-weight-bold">{{ calcularTotal() }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
      color="primary"
      class="float-right mt-4"
      @click="modalDespesasFixas = true"
      >Adicionar <v-icon class="ml-2">mdi-plus</v-icon></v-btn
    >

    <app-despesas-fixas
      v-if="modalDespesasFixas"
      v-model="modalDespesasFixas"
      :tipo="tipo"
      :dre_id="dre_id"
    ></app-despesas-fixas>
  </div>
</template>

<script>
import _util from "../../utils/utils.js";
import AppDespesasFixas from "./AppDespesasFixas.vue";
import _dreLancamento from "../../services/financeiro/dre-lancamento.js";

export default {
  components: {
    AppDespesasFixas,
  },
  props: {
    valores: { type: Array },
    tipo: { require: true, type: Number },
    dre_id: { require: true, type: Number },
  },
  data() {
    return {
      util: _util,
      modalDespesasFixas: false,
    };
  },
  methods: {
    calcularTotal() {
      return this.util.formatarDinheiro(
        this.valores
          .filter((x) => x.tipo === this.tipo)
          .reduce((acumulador, objeto) => acumulador + objeto.valor, 0)
      );
    },

    deletar(id) {
      _dreLancamento
        .deletar(id)
        .then(() => this.$root.$refs.AppFinanceiro.buscar());
    },
  },
};
</script>