<template>
  <div>
    <v-navigation-drawer v-model="drawer" temporary fixed app>
      <v-list>
        <v-list-item link>
          <v-list-item-avatar>
            <!-- <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img> -->
            <img :src="`data:image/png;base64,${img}`" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h7">
              {{ username }}
            </v-list-item-title>
            <v-list-item-subtitle v-on:click="logout">sair <i class="mdi mdi-exit-to-app ml-2" /></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link @click="rota(item.rota)">

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              item.title
            }}</v-list-item-title>
            <!-- <v-list-item-title>{{ item.title }}</v-list-item-title> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white" dark app>
      <v-app-bar-nav-icon icon @click.stop="drawer = !drawer">
        <v-icon color="black">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <!-- <v-img
          class="mx-2"
          src="../assets/icaro-claro.png"
          max-height="150"
          max-width="150"
          contain
        /> -->
      <v-spacer />
      <!-- <v-app-bar-title>Let's Move Academia</v-app-bar-title> -->
      <v-img class="mx-2" src="../assets/connect.jpeg" max-height="150" max-width="150" contain />
      <v-spacer />
      <!--<v-img
          class="mx-2"
          src="../assets/icaro.png"
          max-height="60"
          max-width="60"
          contain
        /> -->
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      dados: [],
      items: [
        { title: "Inicial", icon: "mdi-home", rota: "dashboard" },
        { title: "Usuários", icon: "mdi-account-multiple", rota: "usuarios" },
        { title: "Treinadores", icon: "mdi-weight-lifter", rota: "treinadores" },
        { title: "Alunos", icon: "mdi-account-group", rota: "alunos" },
        { title: "Agenda", icon: "mdi-calendar-month-outline", rota: "agenda" },
        { title: "Ranking Geral", icon: "mdi-podium", rota: "ranking" },
        { title: "Ranking Silva", icon: "mdi-podium", rota: "ranking-academia1" },
        { title: "Ranking Medianeira", icon: "mdi-podium", rota: "ranking-academia2" },
      ],      
      //username:this.$route.params.username,
      username: "",
      img: "",
    };
  },
  created() {
    if (localStorage.dados) {
      this.dados = JSON.parse(localStorage.dados);
    } else {
      this.$router.replace({ name: "login" });
    }
    if (this.dados.admin == false) {
      this.$router.replace({ name: "treinadoralunos" });
    }
  },
  mounted() {
    if (localStorage.dados) {
      this.dados = JSON.parse(localStorage.dados);
      this.username = this.dados["nome"];
      this.img = this.dados["foto"];
      if (this.dados["nivel_acesso"] == 'A')
      {
        this.items.push({ title: "DRE", icon: "mdi-currency-brl", rota: "listafinanceira"})
      }
      if (this.dados["id"] == 636) {
        this.items = [
          { title: "Inicial", icon: "mdi-home", rota: "dazshboard" },
          { title: "Alunos", icon: "mdi-account-group", rota: "alunos" },
        ]
      }
    }
  },
  computed() {
    if (localStorage.dados) {
      this.dados = localStorage.dados;
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.replace({ name: "login" });
    },
    rota(rota) {
      if (rota == 'ranking-academia1')
        this.$router.replace({ path: '/ranking-academia/1' });
      else if (rota == 'ranking-academia2')
        this.$router.replace({ path: '/ranking-academia/2' });
      else
        this.$router.replace({ name: rota });
    },
  },
};
</script>
