 import api from "../api/ferramentas.js"

const cobranca = {

    buscarConfiguracoes() {
        return new Promise((resolve, reject) => {
            api
                .get(`/Cobranca/buscar-config-boletos`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
    
    salvalConfiguracoes(configBoletos) {
        return new Promise((resolve, reject) => {
            api
                .post("/Cobranca/inserir-config-boletos", configBoletos)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
    
    listar(filtros){
        return new Promise((resolve, reject) => {
          api
            .get(`/cobranca/listar/${filtros.usuario ?? " "}/${filtros.vencimento_de}/${filtros.vencimento_ate}`)
            .then((response) => {
              resolve(response.data)
            })
            .catch((erro) => {
              reject(erro)
            })
        })
    },

    listarCobrancasContrato(idContrato){
        return new Promise((resolve, reject) => {
          api
            .get(`/cobranca/listar_cobrancas_contrato/${idContrato}`)
            .then((response) => {
              resolve(response.data)
            })
            .catch((erro) => {
              reject(erro)
            })
        })
    }
}

export default cobranca