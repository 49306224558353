import api from "../api/icaro"

const icaro = {
  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarAlunos() {
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarAlunosAtivos(filtros) {
    return new Promise((resolve, reject) => {
      api
        .post(`/alunos/ativos`, filtros)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
     *
     * @returns {Promise} response.data ou erro
     */
  listarAlunosTreinador(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/treinador/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  buscarAlunoNome(nome){
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/buscar/${nome}`)
        .then((response) => {
          resolve(response.data)
        }).catch((erro) => {
          reject(erro)
        })
    })
  },  

  buscarAlunoTreinadorNome(id, nome){
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/buscar/${id}/${nome}`)
        .then((response) => {
          resolve(response.data)
        }).catch((erro) => {
          reject(erro)
        })
    })
  },  

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  buscarAluno(aluno) {
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/${aluno}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

    /**
   *
   * @returns {Promise} response.data ou erro
   */
    buscarAlunoUsuario(usuario) {
      return new Promise((resolve, reject) => {
        api
          .get(`/alunos/usuario/${usuario}`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((erro) => {
            reject(erro)
          })
      })
    },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  buscarAlunoAtivo(usuario_id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/ativo/${usuario_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  buscarAlunoCompleto(aluno) {
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/completo/${aluno}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} idtreinador
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  buscarAlunosTreinadorDia(idTreinador, dia, horario) {
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/treinador/${idTreinador}/dia/${dia}/horario/${horario}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

   buscarAlunosTreinadorData(idTreinador, dia, horario, data) {
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/treinador/${idTreinador}/dia/${dia}/horario/${horario}/data/${data}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  cadastrarAluno(aluno) {    
    return new Promise((resolve, reject) => {
      api
        .post(`/alunos/`, aluno)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  atualizarAluno(id, aluno) {
    return new Promise((resolve, reject) => {
      api
        .put(`/alunos/${id}`, aluno)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
trasnfereAluno(aluno) {    
  return new Promise((resolve, reject) => {
    api
      .post(`/alunos/transferir/`, aluno)
      .then((response) => {
        resolve(response.data)
      })
      .catch((erro) => {
        reject(erro)
      })
  })
}, 

/**
   * busca a lista de aniversariantes no período informado
   * @param {String} data_inicial
   * @param {String} data_final
   */
listaAniversariantes(data_incial, data_final) {
  return new Promise((resolve, reject) => {
    api
      .get(`/alunos/aniversariantes/${data_incial}/${data_final}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((erro) => {
        reject(erro)
      })
  })
},

  /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  deletarAluno(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/alunos/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  }
  
}

export default icaro