const tipoRelatorio = [
    { text: 'Entradas', value: 1 },
    { text: 'Entradas - Treinador', value: 2 },
    { text: 'Saídas', value: 3 },
    { text: 'Saídas - Treinador', value: 4 },
    { text: 'Transferências', value: 5 },
    { text: 'Contratos', value: 6 },    
    { text: 'Financeiro', value: 7 },
    { text: 'Contratos', value: 8 },
]

export default tipoRelatorio;