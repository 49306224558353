/**
 * @typedef {Object} ConfiguracaoBoleto
 * @property {number} dias_aposvencimento_cancelar
 * @property {number} valor_desconto
 * @property {number} tipo_desconto
 * @property {number} dias_antesvencimento_desconto
 * @property {number} valor_juros_percentual
 * @property {number} valor_multa
 * @property {number} tipo_multa
 * @property {boolean} cobranca_enviada_correio
 */
export default class ConfiguracaoBoleto {
    constructor(
        diasAposVencimentoCancelar,
        valorDesconto,
        tipoDesconto,
        diasAntesVencimentoDesconto,
        valorJuros,
        valorMulta,
        tipoMulta,
        cobrancaEnviadaCorreio,
    ) {
        this.dias_aposvencimento_cancelar = diasAposVencimentoCancelar
        this.valor_desconto = valorDesconto
        this.tipo_desconto = tipoDesconto
        this.dias_antesvencimento_desconto = diasAntesVencimentoDesconto
        this.valor_juros_percentual = valorJuros
        this.valor_multa = valorMulta
        this.tipo_multa = tipoMulta
        this.cobranca_enviada_correio = cobrancaEnviadaCorreio
    }
}