<template>
    <div>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div style="display:flex; align-items: center; width: 100%;">
              <v-icon style="margin-right: 10px">mdi-podium</v-icon>
              <h2 v-if="this.id==1">Ranking Silva</h2>
              <h2 v-if="this.id==2">Ranking Medianeira</h2>
          </div>
        </v-col>
      </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="this.id==1">
            <v-alert 
            outlined
            type="info"
            prominent
            border="left"
            >
            <div class="text-h6">
                Let's Silva | {{this.totais[0]["total"] + this.totais[1]["total"]}}
              </div>
              <v-row align="center">              
                <v-col class="grow">
                  {{this.totais[0]["produto"] + " | " + this.totais[0]["total"]}}
                  <br>
                  {{this.totais[1]["produto"] + " | " + this.totais[1]["total"]}}
                  <!-- Academia | 235<br/> -->
                  <!-- FitHit | 4 -->                                    
                </v-col>
              </v-row>
            </v-alert>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" v-if="this.id==2">
            <v-alert 
            outlined
            type="info"
            prominent
            border="left"
            >
            <div class="text-h6">
                Let's Medianeira | {{this.totais[2]["total"] + this.totais[3]["total"]}}
              </div>
              <v-row align="center">              
                <v-col class="grow">
                  {{this.totais[0]["produto"] + " | " + this.totais[2]["total"]}}
                  <br>
                  {{this.totais[1]["produto"] + " | " + this.totais[3]["total"]}}
                  <!-- Academia | 157<br/> -->
                  <!-- FitHit | 25 -->
                </v-col>
              </v-row>
            </v-alert>
        </v-col>
      </v-row>
        <div v-for="(treinador, index) in ranking" :key="treinador">
  <v-card v-if="treinador.total_alunos > 0"
    class="mx-auto"
    max-width="500"
    outlined
  >
    <v-list-item three-line>

        <v-list-item-avatar        
        size="80"
        color="orange"
      >
      <v-icon v-if="index == 0" color="yellow" size="32">mdi-medal-outline</v-icon>
        <v-icon v-if="index == 1" color="grey" size="32">mdi-medal-outline</v-icon>
        <v-icon v-if="index == 2" color="brown" size="32">mdi-medal-outline</v-icon>
        <h1 v-if="index > 2">{{ index + 1 }}°</h1>
    </v-list-item-avatar>      
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-1">
          {{ treinador.nome }}
        </v-list-item-title>
        <v-list-item-subtitle>
            <span style="color:green"><v-icon color="green">mdi-arrow-up-circle-outline</v-icon> {{ treinador.entradas }}</span>  |
            <span style="color:red"><v-icon color="red">mdi-arrow-down-circle-outline</v-icon> {{ treinador.saidas }}</span>  |
            <span style="color:orange" v-if="treinador.experiencias > 0"><v-icon color="orange">mdi-run-fast</v-icon> {{ treinador.experiencias }}</span>
            <span style="color:blue"><v-icon color="blue">mdi-swap-horizontal</v-icon> {{ treinador.transferencias_entrada }}</span>  |
            <span style="color:red"><v-icon color="red">mdi-swap-horizontal</v-icon> {{ treinador.transferencias_saida }}</span>  |
            <span style="color:black" v-if="treinador.fithit> 0"><v-icon color="orange">mdi-human-female-dance</v-icon> {{ treinador.fithit }}</span>
        </v-list-item-subtitle>
      </v-list-item-content> 
      <v-list-item-avatar        
        size="50"
        color="orange"
      >
        <h2 style="color: white">{{ treinador.total_alunos }}</h2>
    </v-list-item-avatar>          
    </v-list-item>    

    <!-- <v-card-actions>
      <v-btn
        outlined
        rounded
        text
      >
        Button
      </v-btn>
    </v-card-actions> -->
  </v-card>
        <!-- <v-icon v-if="index == 0" color="yellow">mdi-medal-outline</v-icon>
        <v-icon v-if="index == 1" color="grey">mdi-medal-outline</v-icon>
        <v-icon v-if="index == 2" color="brown">mdi-medal-outline</v-icon>
        <span v-if="index > 2">{{ index + 1 }}° - </span>
        <span>{{ treinador.nome }}</span> -->
        </div>        
    </div>
</template>

<script>
import _treinador from '../services/treinador/treinador-servico.js'
export default {
    data() {
        return {
            id: parseInt(this.$route.params.id),
            totais: [],
            ranking: []
        }
    },

    mounted() {
        this.buscarTotais()
        this.buscarRanking(this.id)
    },
    
    methods: {
      async buscarTotais() {
            this.totais = await _treinador.buscarTotais()
        },
        async buscarRanking(id) {
            this.ranking = await _treinador.buscarRankingAcademia(id)
            console.log(this.ranking)
        },
    }
}
</script>