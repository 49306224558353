<!-- <template>
    <div>
      <input type="file" @change="handleFileUpload" />
      <!-- Exibir os dados da planilha, por exemplo, em uma tabela -->
      <table v-if="excelData.length > 0">
        <thead>
          <tr>
            <th v-for="(value, key) in excelData[0]" :key="key">{{ key }}</th>
          </tr>
        </thead>-
        <tbody>
          <tr v-for="(row, index) in excelData" :key="index">
            <td v-for="(value, key) in row" :key="key">{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import * as XLSX from 'xlsx';
  
  export default {
    data() {
      return {
        excelData: [],
      };
    },
    methods: {
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          this.readFile(file);
        }
      },
      readFile(file) {
        const reader = new FileReader();        
        reader.onload = (e) => {            
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });          
          // Transforma os dados em um formato que pode ser exibido no template
          const header = jsonData[0];
          //const linha = jsonData[1];          
        //   const excelData = jsonData.slice(1).map(row =>
        //     Object.fromEntries(row.map((value, index) => [header[index], value]))
        //   );          
        const excelData = jsonData.slice(1).map(row =>
        Object.fromEntries(row.map((value, index) => [header[index], value]))
        );
          this.excelData = excelData;          
        };
  
        reader.readAsBinaryString(file);
      },
    },
  };
  </script>
   -->