<template>
    <div class="text-center">
        <v-snackbar
            :v-model="snackbar"
            :vertical="vertical">
                {{ texto }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="indigo"
                    text
                    v-bind="attrs"
                >
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
  </template>

<script>
    export default {
        props: ['texto', 'snackbar', 'vertical']
    }          
</script>