<template>
  <div>
    <v-text-field
      v-model="campoConsulta"
      prepend-inner-icon="mdi-magnify"
      label="consultar"
      outlined
      dense
      single-line
      hide-details
      clearable
      class="mb-2"
    ></v-text-field>
    <v-data-table
      :headers="cabecalho"
      :items="alunos"
      class="elevation-0"
      :search="campoConsulta"
    >
      <!-- <template v-slot:[`item.entradas`]="{  item }">
      <v-chip color="green lighten-3 green--text text--darken-4" dark label v-if="item.entradas">
        <v-icon>mdi-check</v-icon>
      </v-chip>
    </template>
    <template v-slot:[`item.renovacoes`]="{  item }">
      <v-chip color="green lighten-3 green--text text--darken-4" dark label v-if="item.renovacoes">
        <v-icon>mdi-check</v-icon>
      </v-chip>
    </template>
    <template v-slot:[`item.transferencias`]="{  item }">
      <v-chip color="green lighten-3 green--text text--darken-4" dark label v-if="item.transferencias">
        <v-icon>mdi-check</v-icon>
      </v-chip>
    </template>
    <template v-slot:[`item.saidas`]="{  item }">
      <v-chip color="green lighten-3 green--text text--darken-4" dark label v-if="item.saidas">
        <v-icon>mdi-check</v-icon>
      </v-chip>
    </template> -->
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    alunos: { required: false, type: Array, default: null },
  },
  data() {
    return {
      campoConsulta: null,

      cabecalho: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
        },
        {
          text: "CPF",
          align: "start",
          sortable: true,
          value: "cpf",
        },
        {
          text: "Telefone",
          align: "start",
          sortable: true,
          value: "telefone",
        },
        {
          text: "E-mail",
          align: "start",
          sortable: true,
          value: "email",
        },
        // { text: "Entrada", sortable: false, value: "entradas" },
        // { text: "Renovação", sortable: false, value: "renovacoes" },
        // { text: "Transferência", sortable: false, value: "transferencias" },
        // { text: "Saída", sortable: false, value: "saidas" },
        // { text: "Local", sortable: false, value: "local" },
      ],
    };
  },
  methods: {},
};
</script>