<template>    
  <div class="calendario-semanal" style="overflow-x: auto;">        
      <table style="">
          <thead>
              <tr>
                  <th style="background-color: white;"></th>
                  <th v-for="dia in dias" :key="dia">
                      <span :class="{ 'hoje': isToday(dia) }">{{ dia }}</span>
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="horario in horarios" :key="horario">
                  <td style="width: 50px;">{{ horario.substring(0,2) + 'h' }}</td>
                  <td style="min-width: 3px;" v-for="dia in dias" :key="dia">
                  <!-- <div class="evento" v-for="evento in getEventosParaDia(dia, horario)" :key="evento.id">
                      {{ evento.nome }}            
                  </div> -->
                      <!-- <v-badge :content="2" title="Bruno" overlap>
                          <v-avatar size="36">
                              <img src="https://i.pravatar.cc/300" alt="Imagem do usuário" />                            
                          </v-avatar>                                        
                          <v-icon icon="$vuetify" size="x-large"></v-icon>
                      </v-badge> -->
                      <div v-for="evento in getEventosParaDia(dia, horario)" :key="evento.id" class="d-inline-block ma-2">
                          <v-badge :content="evento.alunos" :title="evento.nome" overlap bordered color="grey">
                              <v-badge
                                  bordered
                                  bottom
                                  color="orange"
                                  dot
                                  offset-x="5"
                                  offset-y="5"
                                  transparent
                                  v-if="evento.experiencia"
                              >
                                  <v-avatar size="30" color="orange">
                                      <v-img :src=evento.avatar v-if="evento.avatar"></v-img>
                                      <span v-else>{{ inicialNome(evento.nome) }}</span>
                                      <!-- <v-img :src="evento.avatar ? evento.avatar : 'https://icaro.fit/img/logo-preto.png'"/> -->
                                  </v-avatar>
                              </v-badge>
                                  <v-avatar size="30" color="orange" v-if="evento.experiencia==false"
                                  @click="abreModal(evento.nome, dia, horario, evento.dia_semana, evento.treinador_id)">
                                      <v-img :src=evento.avatar v-if="evento.avatar"></v-img>
                                      <span v-else>{{ inicialNome(evento.nome) }}</span>
                                  </v-avatar>
                          </v-badge>
                          <br/>
                          <span style="font-size: 12px;">{{ formataNome(evento.nome) }}</span>                            
                      </div>                
                  </td>
              </tr>
              <v-dialog v-model="dialog"  max-width="500px">
                  <v-card>
                      <v-toolbar
                      color="primary"
                      dark
                      >{{ titulo }}</v-toolbar>
                      <v-card-text>
                      <!-- <div class="text-h2 pa-12">Hello world!</div> -->
                      <div v-for="aluno in this.alunos" :key="aluno.id" class="ma-2">
                          {{ aluno.nome }}
                      </div>                        
                      </v-card-text>
                      <v-card-actions class="justify-end">
                      <v-btn
                          text
                          @click="fechaModal()"
                      >Close</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
          </tbody>
      </table>        
  </div>
</template>

<script>
import _utils from '../utils/utils.js'
import _treinador from '../services/treinador/treinador-servico.js'
import _alunos from '../services/aluno/aluno-servico.js'

export default {        
  data() {
      return {
          nome1: "",
          nome2: "",
          dias: ["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
          horarios: [
              "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
              "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00",
              ],
          eventos1: [
              //{ id: 1, nome: "Bruno B.", avatar: "https://i.pravatar.cc/300?img=3", alunos: 4, experiencia: true, dia: "Segunda", horario: "06:00" },
              //{ id: 2, nome: "Bibiana", avatar: "https://i.pravatar.cc/300?img=5", alunos: 2, experiencia: false, dia: "Segunda", horario: "06:00" },
              //{ id: 1, nome: "Bruno B.", avatar: "https://i.pravatar.cc/300?img=3", alunos: 3, experiencia: false, dia: "Quarta", horario: "06:00" },
              //{ id: 2, nome: "Bibiana", avatar: "https://i.pravatar.cc/300?img=5", alunos: 5, experiencia: false, dia: "Quarta", horario: "06:00" },
              //{ id: 1, nome: "Bruno B.", avatar: "https://i.pravatar.cc/300?img=3", alunos: 2, experiencia: false, dia: "Sexta", horario: "06:00" },
              //{ id: 2, nome: "Bibiana", avatar: "https://i.pravatar.cc/300?img=5", alunos: 1, experiencia: false, dia: "Sexta", horario: "06:00" },
              //{ id: 3, nome: "Apresentação de slides", avatar: "https://i.pravatar.cc/300", dia: "Quarta", horario: "18:00" },
              //{ id: 4, nome: "Almoço com cliente", avatar: "https://i.pravatar.cc/300", dia: "Sexta", horario: "12:00" },
              {id: 1, dia: "Segunda", nome: "Aline Salanha", alunos: 2, avatar: "", horario: "06:00", experiencia: true},
              {id: 2, dia: "Segunda", nome: "Bruno Bitencourt", alunos: 5, avatar: "https://i.pravatar.cc/300?img=2", horario: "06:00", experiencia: false},
              {id: 3, dia: "Segunda", nome: "Matheus Righi Furlan", alunos: 6, avatar: "https://i.pravatar.cc/300?img=3", horario: "06:00", experiencia: true},
              {id: 4, dia: "Segunda", nome: "Miriam Ferreira Soares", alunos: 2, avatar: "https://i.pravatar.cc/300?img=5", horario: "06:00", experiencia: true},
              {id: 5, dia: "Segunda", nome: "Tassiano da Rocha Rossato", alunos: 4, avatar: "https://i.pravatar.cc/300?img=4", horario: "06:00", experiencia: false},
          ],
          eventos: [],
          dialog: false,
          titulo: "",
          alunos: [],
      };
  },
  async mounted() {
      //console.log(JSON.parse(this.eventos1))
      this.eventos = await _treinador.listarAgendaGeral()
  },
  methods: {
      getEventosParaDia(dia, horario) {
          return this.eventos.filter(evento => evento.dia === dia && evento.horario === horario);
      },
      isToday(dia) {
          const hoje = new Date().toLocaleString('pt-BR', { weekday: 'long' }).split('-')[0].trim();        
          return dia.toLowerCase() === hoje.toLowerCase()
      },
      formataNome(nome){
          return _utils.formataNomeCompleto(nome)            
      },
      inicialNome(nome){                        
          return nome[0]
      },
      async abreModal(nome, dia, horario, dia_semana, treinador){
          this.titulo = nome + " | " + dia + " | " + horario
          this.dialog = true
          this.alunos = await this.buscaAlunos(treinador, dia_semana, horario)            
      },
      buscaAlunos(id, dia, horario)
      {            
          return _alunos.buscarAlunosTreinadorDia(id, dia, horario)
      },
      fechaModal()
      {
          this.dialog = false
          this.titulo = ""
          this.alunos = []
      }
  },
};
</script>

<style scoped>
.calendario-semanal {
  font-family: Arial, sans-serif;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}

table th.hoje {
  background-color: #ffcc00;
}

table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.evento {
  background-color: #007bff;
  color: #fff;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}

.hoje {
  color: #ffcc00;
}

@media (max-width: 768px) {
table {
      font-size: 12px;
  }
}
</style>