<template>
  <div>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card>
      <v-toolbar color="primary" dark
        >Configurações para cobranças por boleto
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('fechar')">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <ValidationObserver ref="form" #default="{ handleSubmit }">
        <v-form
          @keyup.enter="handleSubmit(salvar)"
          @submit.prevent="handleSubmit(salvar)"
        >
          <v-card-text class="mt-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  type="Number"
                  v-model="configBoleto.dias_aposvencimento_cancelar"
                  label="Dias vencido cancelar"
                  outlined
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" md="6">
                <v-select
                  :items="tiposValor"
                  v-model="configBoleto.tipo_desconto"
                  label="Tipo de Desconto"
                  item-text="descricao"
                  item-value="tipo"
                  outlined
                  hide-details
                  @change="alterarPrefixoCampo(true)"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="Desconto"
                  v-money="descontoConfig"
                >
                  <v-text-field
                    v-model="configBoleto.valor_desconto"
                    label="Desconto"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  type="Number"
                  v-model="configBoleto.dias_antesvencimento_desconto"
                  label="Dias antes conceder"
                  outlined
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" md="6">
                <v-select
                  :items="tiposValor"
                  v-model="configBoleto.tipo_multa"
                  label="Tipo de Multa"
                  item-text="descricao"
                  item-value="tipo"
                  outlined
                  hide-details
                  @change="alterarPrefixoCampo(false)"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="Multa"
                  v-money="multaConfig"
                >
                  <v-text-field
                    v-model="configBoleto.valor_multa"
                    label="Multa"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="Juros"
                  v-money="jurosConfig"
                >
                  <v-text-field
                    v-model="configBoleto.valor_juros_percentual"
                    label="Juros"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="configBoleto.cobranca_enviada_correio"
                  label="Enviar por correio"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </ValidationObserver>
      <v-card-actions class="justify-end">
        <v-btn text color="error" @click="$emit('fechar')"> Cancelar </v-btn>
        <v-btn color="primary" @click="salvar()"
          >Salvar <v-icon class="ml-2">mdi mdi-content-save</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>    
  </v-dialog>  
  <v-snackbar v-model="snackbar.mostrar" :color="snackbar.cor">
      {{ snackbar.texto }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.mostrar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import _cobrancas from "../../services/cobranca/cobranca.js";

import ConfiguracaoBoleto from "../../models/configuracao-boleto.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    value: Boolean,
  },

  data() {
    return {
      snackbar: {
        mostrar: false,
        texto: "",
        cor: "",
      },

      configBoleto: new ConfiguracaoBoleto(0, 0, 0, 0, 0, 0, 0, false),

      tiposValor: [
        { tipo: 1, descricao: "Valor" },
        { tipo: 2, descricao: "Porcentagem" },
      ],

      descontoConfig: {
        // Configuration options for v-money
        decimal: ",",
        // Decimal separator
        thousands: ".",
        // Thousands separator
        prefix: "",
        // Currency symbol
        precision: 2,
        // Number of decimal places
        masked: false,
        // Do not show placeholders for unfilled digits
        allowBlank: false,
        // Do not allow an empty value
      },

      multaConfig: {
        // Configuration options for v-money
        decimal: ",",
        // Decimal separator
        thousands: ".",
        // Thousands separator
        prefix: "",
        // Currency symbol
        precision: 2,
        // Number of decimal places
        masked: false,
        // Do not show placeholders for unfilled digits
        allowBlank: false,
        // Do not allow an empty value
      },

      jurosConfig: {
        // Configuration options for v-money
        decimal: ",",
        // Decimal separator
        thousands: ".",
        // Thousands separator
        prefix: "% ",
        // Currency symbol
        precision: 2,
        // Number of decimal places
        masked: false,
        // Do not show placeholders for unfilled digits
        allowBlank: false,
        // Do not allow an empty value
      },
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  mounted() {
    this.buscarConfiguracao();
  },

  methods: {
    notificar(texto, cor = "primary") {
      this.snackbar.cor = cor;
      this.snackbar.texto = texto;
      this.snackbar.mostrar = true;
    },

    alterarPrefixoCampo(desconto) {
      if (desconto) {
        this.descontoConfig.prefix =
          this.configBoleto.tipo_desconto === 1 ? "R$ " : "% ";
      } else {
        this.multaConfig.prefix =
          this.configBoleto.tipo_multa === 1 ? "R$ " : "% ";
      }
    },

    async buscarConfiguracao() {
      try {
        _cobrancas.buscarConfiguracoes().then((config) => {
          if (config) {
            config.valor_desconto = parseFloat(config.valor_desconto).toFixed(
              2
            );
            config.valor_multa = parseFloat(config.valor_multa).toFixed(2);
            config.valor_juros_percentual = parseFloat(
              config.valor_juros_percentual
            ).toFixed(2);

            this.configBoleto = config;
            this.alterarPrefixoCampo(true);
            this.alterarPrefixoCampo(false);
          }
        });
      } catch (erro) {
        this.notificar(
          "Erro ao buscar a configuração de boletos!",
          "error"
        );
      }
    },

    async salvar() {
      try {
        this.configBoleto.valor_desconto = parseFloat(
          this.configBoleto.valor_desconto
            .replace(/[^\d-,]/g, "")
            .replace(",", ".")
        ).toFixed(2);
        this.configBoleto.valor_multa = parseFloat(
          this.configBoleto.valor_multa
            .replace(/[^\d-,]/g, "")
            .replace(",", ".")
        ).toFixed(2);
        this.configBoleto.valor_juros_percentual = parseFloat(
          this.configBoleto.valor_juros_percentual
            .replace(/[^\d-,]/g, "")
            .replace(",", ".")
        ).toFixed(2);

        _cobrancas.salvalConfiguracoes(this.configBoleto).then(() => {
          this.cobrancaSelecionada = null;
          this.notificar(
            "Configurações de boleto salvas com sucesso!",
            "success"
          );

          this.$emit("fechar");
        })
        .catch(() => {
          this.cobrancaSelecionada = null;
          this.notificar(
            "Erro ao salvar as configurações de boleto!",
            "error"
          );
        });

      } catch (erro) {
        console.error("Erro ao salvar configuração boleto:", erro);
      }
    },
  },
};
</script>