/**
 * @typedef {Object} DreLancamento
 * @property {number} id
 * @property {number} dre_id  
 * @property {Date} criacao
 * @property {Date} atualizacao
 * @property {Date} exclusao
 * @property {String} descricao
 * @property {number} valor
 * @property {number} tipo
 * @property {Date} data_inicial
 * @property {Date} data_final 
 */
export default class DreLancamento {
    constructor(
      id,
      dre_id,
      criacao,
      atualizacao,
      exclusao,
      descricao,
      valor,
      tipo,
      data_inicial,
      data_final
    ) {
      this.id = id
      this.dre_id = dre_id
      this.criacao= criacao
      this.atualizacao = atualizacao
      this.exclusao = exclusao
      this.descricao = descricao
      this.valor = valor
      this.tipo = tipo
      this.data_inicial = data_inicial
      this.data_final = data_final
    }
  }
