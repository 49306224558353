const _assinatura = [
    {
        valor: 1,
        descricao: "Em construção"
    },
    {
        valor: 2,
        descricao: "Aguardando Assinaturas"
    },
    {
        valor: 3,
        descricao: "Concluído"
    },
    {
        valor: 4,
        descricao: "Arquivado"
    },
    {
        valor: 5,
        descricao: "Cancelado"
    },
    {
        valor: 6,
        descricao: "Expirado"
    },
]

export default _assinatura
