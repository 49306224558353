import api from "../api/icaro"

const icaro = {
  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarContratos() {
    return new Promise((resolve, reject) => {
      api
        .get(`/contratos/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  buscarContratoAluno(aluno_id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/contratos/${aluno_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} contrato
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  cadastrarContrato(contrato) {
    return new Promise((resolve, reject) => {
      api
        .post(`/contratos/`, contrato)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
    * @param {Object} contrato
    * @returns {Promise} response.data ou erro
    * @memberof icaro
   **/
  atualizarContrato(id, contrato) {
    return new Promise((resolve, reject) => {
      api
        .put(`/contratos/${id}`, contrato)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Number} contrato_id
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   */
  excluirContrato(contrato_id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/contratos/${contrato_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * busca os dados completos do aluno para popular o contrato
   * @param {Number} id 
   */
  buscarDadosContrato(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/contratos/completo/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * busca a lista de contratos vencendo no período informado
   * @param {String} data_inicial
   * @param {String} data_final
   */
  listaContratoVencimento(data_incial, data_final) {
    return new Promise((resolve, reject) => {
      api
        .get(`/contratos/vencimento/${data_incial}/${data_final}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },
}

export default icaro