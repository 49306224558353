<template>
  <ValidationObserver ref="form" #default="{ handleSubmit, reset }"  mode="eager">
    <form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
      <ValidationProvider name="E-mail" rules="required|email" #default="{ errors }">
        <v-text-field v-model="email" type="email"/>
        <span>{{ errors[0] }}</span>
      </ValidationProvider>

      <ValidationProvider name="First Name" rules="required|alpha" v-slot="{ errors }">
        <v-text-field v-model="firstName" type="text"/>
        <span>{{ errors[0] }}</span>
      </ValidationProvider>

      <ValidationProvider name="Last Name" rules="required|alpha" v-slot="{ errors }">
        <v-text-field v-model="lastName" type="text"/>
        <span>{{ errors[0] }}</span>
      </ValidationProvider>

      <v-btn type="submit">Submit</v-btn>
      <v-btn type="reset">Reset</v-btn>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  data: () => ({
    firstName: '',
    lastName: '',
    email: ''
  }),
  methods: {
    onSubmit () {
      alert('Form has been submitted!');
    }
  }
};
</script>