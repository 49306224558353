<template>
    <!-- <v-app-bar color="#FF562B" dark fixed app prominent dense> -->
      <v-app-bar
      color="#fcb69f"
      dark
      src="../assets/background.jpg"
      scroll-target="#scrolling-techniques-2"
      prominent
      dense
    >
        <v-app-bar-nav-icon v-if="treinador" icon v-on:click="rota('treinadorinicial')">
            <v-icon>mdi-home</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon icon v-on:click="rota(destino)">
            <v-icon>mdi-arrow-left-circle</v-icon>
        </v-app-bar-nav-icon>
        <!-- <v-img
          class="mx-2"
          src="../assets/icaro.png"
          max-height="40"
          max-width="40"
          contain
        /> -->        
        <v-app-bar-title>{{ titulo }}</v-app-bar-title>
        <v-spacer/>
        <v-img
          class="mx-2"
          src="../assets/icaro-claro.png"
          max-height="150"
          max-width="150"
          contain
        />        
    </v-app-bar>    
</template>

<script>
export default {
  components: {
    
},
  
  props: {
    titulo: String,
    destino: String
  },

  data () {
      return {  
        treinador: false,
      }
    },

  created(){
      if(localStorage.dados) {        
        this.dados = JSON.parse(localStorage.dados)
      }
      else
      {
        this.$router.replace({name:'login'})
      }
      if (this.dados.admin == false) 
      {
        //this.$router.replace({name:'treinadoralunos'})
        this.treinador = true
      }
  },

  mounted() {    

  },

  methods: {    
    rota(rota) {
      console.log(rota)
      if (rota == "")
        {
          rota = this.$props.destino
        }
        console.log(rota)
        this.$router.replace({name:rota})
      },
    
    //...mapActions({
    //   mostrarCarregando: "carregando/mostrarCarregando",
    //   mostrarMensagem: "mensagem/mostrarMensagem",
    //}),
  },
}
</script>

<style>
.v-app-bar-title__content{
  width: 500px !important;
}
</style>