<template>
  <v-container fluid>
    <AppBar titulo="" destino="dashboard" />
    <v-toolbar color="orange" dense>
      <v-toolbar-title>Treinadores</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <br />
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <v-card-title> Filtros </v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-btn color="blue" dark
              >Buscar <v-icon class="ml-2">mdi-magnify</v-icon></v-btn
            >
            <v-btn color="yellow" dark
              >Limpar <v-icon class="ml-2">mdi-eraser</v-icon></v-btn
            >
            <v-btn
              class="ml-auto"
              color="#FF562B"
              dark
              v-on:click="rota('cadastrotreinadores')"
              v-if = "admin==true"
              >Cadastrar <v-icon class="ml-2">mdi-plus</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <h3 class="mb-2">Lista de treinadores</h3>

        <v-card>
          <v-card-text>
            <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            clearable
                            label="consultar"
                            single-line
                            outlined
                            dense
                            hide-details
                        />
            <v-data-table
              :headers="headers"
              :items="treinadores"
              :items-per-page="10"              
              :search="search"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Itens por página',
              }"
            >
              <template v-slot:[`item.cadastro`]="{ item }">
                <v-icon medium class="mr-2" @click="editarTreinador(item)">
                  mdi-pencil-outline
                </v-icon>
              </template>
              <template v-slot:[`item.alunos`]="{ item }">
                <v-icon medium class="mr-2" @click="abrirTreinadorAlunos(item)">
                  mdi-open-in-new
                </v-icon>
              </template>
              <template v-slot:[`item.agenda`]="{ item }">
                <v-icon medium class="mr-2" @click="abrirAgenda(item)">
                  mdi-open-in-new
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-spacer>
                  <div>
                    <br />
                  </div>
                </v-spacer>
                <v-alert
                  :value="true"
                  type="info"
                  icon="mdi-alert-circle-outline"
                  color="warning"
                  dark
                >
                  Nenhum treinador encontrado.
                </v-alert>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _treinadores from "../../services/treinador/treinador-servico.js";
import AppBar from "../../components/AppBar.vue";
export default {
  components: {
    AppBar,
  },

  props: {},

  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "nome",
        },
        { text: "Data Inicial", value: "data_inicial" },
        { text: "Data Final", value: "data_final" },
        {
          text: "Cadastro",
          value: "cadastro",
          align: "center",
          sortable: false,
        },
        { text: "Alunos", value: "alunos" },
        { text: "Agenda", value: "agenda" },
      ],
      treinadores: [],
      admin: true,
    };
  },

  created() {
    // this.$vuetify.theme.dark = parseInt(this.$route.params.tema) === 1
  },

  mounted() {
    if (localStorage.dados) {
      this.dados = JSON.parse(localStorage.dados);
    }
    if (this.dados["id"] == 636) {
        this.admin = false
    }

    this.listartreinadores();
  },

  methods: {
    listartreinadores() {
      _treinadores.listartreinadores().then((response) => {
        this.treinadores = response;
      });
    },
    editarTreinador(treinador) {
      this.$router.push({
        name: "cadastrotreinadores",
        params: { id: treinador.id },
      });
    },
    abrirTreinadorAlunos(treinador){
      this.$router.push({
        name: "agenda-alunos",
        params: { treinadorId: treinador.id },
      });
    },
    abrirAgenda(treinador){
      this.$router.push({
        name: "treinadoragenda",
        params: { id: treinador.id },
      });
    },
    limpar() {
      //this.dialogs.dialog = true;
      // this.nome = ""
      // this.usuarios = []
    },
    rota(rota) {
      this.$router.replace({ name: rota });
    },
  },
};
</script>