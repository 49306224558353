<template>    
    <v-container fluid>
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary>
            <v-list>
                <v-list-item link>
                    <v-list-item-avatar>
                        <img :src="`data:image/png;base64,${img}`"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-h7">
                        {{username}}
                        </v-list-item-title>
                        <v-list-item-subtitle v-on:click="logout">sair</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider/>
            <v-list
                dense
                nav
            >
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title @click="rota(item.rota)">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-row align="center" justify="center" class="mb-6" no-gutterss>
            <v-col col="12" md="12" xl="12" xs="12" sm="12">
                <v-app-bar color="#FF562B" dark fixed app>
                    <v-app-bar-nav-icon icon @click.stop="drawer = !drawer">
                        <v-icon>mdi-menu</v-icon>
                    </v-app-bar-nav-icon>            
                    <v-spacer />
                    <v-img
                        class="mx-2"
                        src="../../assets/icaro-claro.png"
                        max-height="150"
                        max-width="150"
                        contain/>
                    <v-spacer />            
                </v-app-bar>                
                    <v-row style="background-color:orange; height: 100px;">
                        <v-spacer></v-spacer>
                        <v-col col="3" align-self="center">
                            <v-avatar size="80px"><img :src="`data:image/png;base64,${img}`" style="width: 100%; height: 100%;" /></v-avatar>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col col="3" align-self="center">
                            <span>{{username}}</span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col col="3" align-self="center">
                            <span>
                                <v-icon color="yellow">mdi-star</v-icon>
                                <v-icon color="yellow">mdi-star</v-icon>
                                <v-icon color="yellow">mdi-star</v-icon>
                                <v-icon color="yellow">mdi-star</v-icon>
                                <v-icon color="white">mdi-star</v-icon>
                            </span>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
            </v-col>
        </v-row>
        <v-row>            
            
        </v-row>
        <v-row>
            <v-col col="6">
                <v-card min-height="161px">
                    <v-card-title class="justify-center">
                        Alunos
                    </v-card-title>
                    <v-card-text class="text-center">
                        <span style="font-size:3.5em">{{ this.alunos.length }}</span>
                    </v-card-text>
                    <v-card-actions><v-btn @click="rota('treinadoralunos')">Ver alunos</v-btn></v-card-actions>
                </v-card>
            </v-col>
            <!-- <v-col col="6">
                <v-card>
                    <v-card-title class="justify-center">
                        Percentual de Frequência
                    </v-card-title>
                    <v-card-text class="text-center">
                        <span style="font-size:3.5em">0%</span>
                    </v-card-text>
                </v-card>
            </v-col>             -->
        <!-- </v-row> -->
        <!-- <v-row> -->
            <v-col col="6" min-height="161px">
                <v-card min-height="161px">
                    <v-card-title class="justify-center">
                        Contratos
                    </v-card-title>
                    <v-card-text class="text-center">
                        <v-row>
                        <v-col col="6">
                            <v-row><v-col><span style="font-size:1em">Novos</span></v-col></v-row>
                            <v-row><v-col><span style="font-size:3.5em">0</span></v-col></v-row>
                        </v-col>
                        <v-col col="6">
                            <v-row><v-col><span style="font-size:1em">Vencendo</span></v-col></v-row>
                            <v-row><v-col><span style="font-size:3.5em">0</span></v-col></v-row>
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-card>
            </v-col>            
        </v-row>                        
        <v-row>
            <v-col col="12">
                <AppTreinosDia></AppTreinosDia>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col col="6">
                <AppTreinosDia></AppTreinosDia>
            </v-col>
            <v-col col="12">
                <AppAgenda></AppAgenda>
            </v-col>
        </v-row> -->
    </v-container>
</template>

<script> 
import _alunos from '../../services/aluno/aluno-servico.js'
// import AppAgenda from "./AppAgenda.vue";
import AppTreinosDia from "./AppTreinosDia.vue";

export default {
    components: {
        // AppAgenda,
        AppTreinosDia
    }, 
    data () {
        return {
            drawer: null,
            dados: [],
            items: [
                // { title: 'Usuários', icon: 'mdi-view-dashboard', rota: 'usuarios'},
                // { title: 'Treinadores', icon: 'mdi-image', rota: 'treinadores'},
                { title: 'Alunos', icon: 'mdi-help-box', rota: 'treinadoralunos'},
            ],
            username: '',
            img: '',
            alunos: [],
        }
    },
    mounted(){
        if(localStorage.dados) {        
            this.dados = JSON.parse(localStorage.dados)
            this.username = this.dados['nome']
            this.img = this.dados['foto']
        }
        this.buscaAlunosAtivosPersonal()
    },
    // computed() {
    //     if(localStorage.dados) {        
    //         this.dados = localStorage.dados
    //         console.log(this.dados)        
    //     }
    // },
    methods: {
        logout() {
            localStorage.clear()
            this.$router.replace({name:'login'})
        },
        rota(rota) {        
            this.$router.replace({name:rota})
        },
        buscaAlunosAtivosPersonal () {            
            _alunos.listarAlunosTreinador(this.dados.relacionamento_id).then(response => {
                console.log(response)
                this.alunos = response.filter(function (el){
                return el.data_final == null
                })
                if (this.alunos != [])
                {
                    localStorage.alunos = JSON.stringify(this.alunos);
                }                
            })
        }
    }
}
</script>