import api from "./api/ferramentas.js"

const asaas = {

   buscarQrCode(idAcademia, idCobranca) {
       return new Promise((resolve, reject) => {
           api
               .get(`/asaas/qrcode/${idAcademia}/${idCobranca}`)
               .then((response) => {
                   resolve(response.data)
               })
               .catch((erro) => {
                   reject(erro)
               })
       })
   },

   recebidoEmDinheiro(idAcademia, idCobranca, recebimento) {
       return new Promise((resolve, reject) => {
           api
               .post(`/asaas/recebido-em-dinheiro/${idAcademia}/${idCobranca}`, recebimento)
               .then((response) => {
                   resolve(response.data)
               })
               .catch((erro) => {
                   reject(erro)
               })
       })
   },

   gerarNotaFiscal(idAcademia, idCobranca) {
       return new Promise((resolve, reject) => {
           api
               .post(`/asaas/nota-fiscal/${idAcademia}/${idCobranca}`)
               .then((response) => {
                   resolve(response.data)
               })
               .catch((erro) => {                
                   reject(erro)
               })
       })
   },

   gerarCobrancasContrato(idContrato) {
       return new Promise((resolve, reject) => {
           api
               .post(`/asaas/cobrancas-contrato/${idContrato}`)
               .then((response) => {
                   resolve(response.data)
               })
               .catch((erro) => {                
                   reject(erro)
               })
       })
   },
}

export default asaas