import api from "../api/icaro"

const icaro = {
  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarUsuarios() {
    return new Promise((resolve, reject) => {
      api
        .get(`/usuarios/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },
  
  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarUsuariosAtivos() {
    return new Promise((resolve, reject) => {
      api
        .get(`/usuarios/ativos/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  listarUsuariosNovos() {
    return new Promise((resolve, reject) => {
      api
        .get(`/usuarios/ativos/sem-relacionamento/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  listarUsuariosSemRelacionamento(relacionamento) {
    return new Promise((resolve, reject) => {
      api
        .get(`/usuarios/buscarsemrelacionamento/${relacionamento}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  listar() {
    return new Promise((resolve, reject) => {
      api
        .get(`/usuarios/listar/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  buscarUsuarios(nome){
    return new Promise((resolve, reject) => {
      api
        .get(`/usuarios/buscar/${nome}`)
        .then((response) => {
          resolve(response.data)
        }).catch((erro) => {
          reject(erro)
        })
    })
  },

  buscarUsuario(id){
    return new Promise((resolve, reject) => {
      api
        .get(`/usuarios/${id}`)
        .then((response) => {
          resolve(response.data)
        }).catch((erro) => {
          reject(erro)
        })
    })
  },

  buscarUsuarioCpf(cpf){
    return new Promise((resolve, reject) => {
      api
        .get(`/alunos/cpf/${cpf}`)
        .then((response) => {
          resolve(response.data)
        }).catch((erro) => {
          reject(erro)
        })
    })
  },
  
  cadastrarUsuario(usuario) {
    return new Promise((resolve, reject) => {
      console.log(usuario)
      api
        .post(`/usuarios/`, usuario)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  cadastrarUsuarioExterno(usuario) {
    return new Promise((resolve, reject) => {
      console.log(usuario)
      api
        .post(`/usuarios/externo/`, usuario)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  atualizarUsuario(id, usuario) {    
    return new Promise((resolve, reject) => {
      api
        .put(`/usuarios/${id}`, usuario)
        .then((response) => {
          resolve(response.data)
        }).catch((erro) => {
          reject(erro)
        })
    })
  },
  
  deletarUsuario(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/usuarios/${id}`)
        .then((response) => {
          resolve(response.data)
        }).catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  //  login(usuario, senha) {
    login(form) {
    return new Promise((resolve, reject) => {
      api
        .post(`/usuarios/login`, form)        
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },
}

export default icaro