<template>
    <div>
        <v-container fluid>
            <AppBar titulo="" destino="dashboard" />
            <v-toolbar color="orange" dense>
                <v-toolbar-title>Relatórios</v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <br />
            <v-row align="center" justify="center">
                <v-col col="12" md="12" xl="12" xs="12" sm="12">
                    <v-card>
                        <v-card-title> Filtros </v-card-title>
                        <ValidationObserver ref="form" #default="{ handleSubmit }">
                            <v-form
                            @keyup.enter="handleSubmit(buscar)"
                            @submit.prevent="handleSubmit(buscar)"
                            >
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4" xl="4">
                                            <ValidationProvider
                                                #default="{ errors }"
                                                :rules="{ required: true }"
                                                name="relatório"
                                                >
                                                <v-select
                                                        v-model="relatorio"
                                                        :items="relatorios"
                                                        label="Relatório"
                                                        :error-messages="errors[0]"
                                                        outlined
                                                    />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" xl="4">
                                            <v-text-field
                                                v-model="data_inicial"
                                                label="Data De"
                                                color="#FF562B"                                        
                                                outlined
                                                v-mask="'##/##/####'"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" xl="4">
                                            <v-text-field
                                                v-model="data_final"
                                                label="Data Até"
                                                color="#FF562B"                                        
                                                outlined
                                                v-mask="'##/##/####'"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="blue" dark type="submit"
                                    >Buscar <v-icon class="ml-2">mdi-magnify</v-icon></v-btn>
                                    <v-btn color="#ccc" dark @click="limparCampos()">Limpar</v-btn>
                                </v-card-actions>
                            </v-form>
                        </ValidationObserver>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col col="12" md="12" xl="12" xs="12" sm="12">
                    <h3 class="mb-2">Resultados {{ resultados != "" ? this.getRelatorio(this.relatorio) +  ' - ' + this.totais : ' ' }}</h3>
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="resultados"
                            :items-per-page="10"
                            :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-text': 'Itens por página',
                            }"
                        > 
                        <template v-slot:[`item.data_inicial`]="{ item }">            
                            {{  formatarData(item.data_inicial) }}
                        </template>
                        <template v-slot:[`item.situacao`]="{ item }">            
                            {{  getSituacao(item.situacao) }}
                        </template>
                        <template v-slot:no-data>
                            <v-spacer>
                                <div>
                                <br />
                                </div>
                            </v-spacer>
                            <v-alert
                                :value="true"
                                type="info"
                                icon="mdi-alert-circle-outline"
                                color="warning"
                                dark
                            >
                                Nenhum registro encontrado.
                            </v-alert>
                        </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
import AppBar from "../components/AppBar.vue";
import _relatoriosServico from "../services/relatorios/relatorios-servico.js"
import _relatorios from "../models/tipo-relatorio.js"
import _situacao_aluno from "../models/situacao-aluno.js"
import _utils from '../utils/utils.js'

export default {
    components: {
        AppBar,
    },

    props: {},
    computed: {
    totalAlunos() {
        return this.resultados.reduce((acc, treinador) => acc + treinador.alunos, 0);
        }
    },
    data() {
        return {
            headers: [],
            relatorios: _relatorios,
            relatorio: "",
            data_inicial: "",
            data_final: "",
            resultados: [],
            totais: "",
        }
    },

    methods: {
        buscar(){
            this.filtros = {
                    data_de: this.data_inicial == "" ? null : _utils.formatarData(this.data_inicial),
                    data_ate: this.data_final == "" ? null : _utils.formatarData(this.data_final),
                    // treinador_id: this.treinador == "" ? 0 : this.treinador,
                    // academia_id: this.academia == "" ? 0 : this.academia,
                    // produto: this.produto == "Academia" ? 0 : this.produto == "Fithit" ? 1 : null,
                    // situacao: this.situacao == "" ? null : this.situacao                    
                };

            this.montaRelatorio()            
        },
        montaRelatorio(){
            if (this.relatorio == 1)
            {
                this.headers= [
                    { text: "Código", value: "id" },
                    { text: "Nome", value: "nome" },
                    { text: "Data Inicial", value: "data_inicial" },
                    { text: "Situação", value: "situacao" },
                ];
                
                _relatoriosServico.listarEntradas(this.filtros).then((response) => {
                this.resultados = response;
                this.totais = response.length;
            })
            }
            else if (this.relatorio == 2){
                this.headers= [
                    { text: "Treinador", value: "nome" },
                    { text: "Alunos", value: "alunos" },
                ];
                
                _relatoriosServico.listarEntradasTreinadores(this.filtros).then((response) => {
                this.resultados = response;
                this.totais = this.totalAlunos
            })
            }
            if (this.relatorio == 3)
            {
                this.headers= [
                    { text: "Código", value: "id" },
                    { text: "Nome", value: "nome" },
                    { text: "Data Inicial", value: "data_inicial" },
                    { text: "Situação", value: "situacao" },
                ];
                console.log(this.filtros)
                _relatoriosServico.listarSaidas(this.filtros).then((response) => {
                console.log(response)
                this.resultados = response;
                this.totais = response.length;
            })
            }
            else if (this.relatorio == 4){
                this.headers= [
                    { text: "Treinador", value: "nome" },
                    { text: "Alunos", value: "alunos" },
                ];
                
                _relatoriosServico.listarSaidasTreinadores(this.filtros).then((response) => {
                this.resultados = response;
                this.totais = this.totalAlunos
            })
            }
        },
        limparCampos(){
            this.relatorio = "";
            this.data_inicial = "";
            this.data_final = "";
            this.resultados = [];
        },
        formatarData(data)
        {
            if (data != null)
                return _utils.formatarDataTela(data);
        },
        getRelatorio(relatorio) {      
            return _relatorios[relatorio-1].text;
        },
        getSituacao(situacao) {      
            return _situacao_aluno[situacao-1].text;
        },
    }    
}
</script>
