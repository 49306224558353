<template>
  <v-chip
    color="#FCFCFC"
    label
    class="font-weight-bold ma-1 aling-center justify-center"
    style="min-width: 160px"
  >
    <!-- <v-chip label small class="mr-2 text-body-2 font-weight-bold">{{
      valor
    }}</v-chip> -->
    <v-icon
      left
      :color="`${valor > 0 && !saida ? 'green' : valor > 0 && saida ? 'red' : 'grey'}`"
      class="mr-0"
    >
      mdi-{{
        valor > 0 && !saida ? "arrow-up" : valor > 0 && saida ? "arrow-down" : "minus"
      }}
    </v-icon>
    <div class="" style="margin-top: 2px;">
      <span
        :class="`${
          valor > 0 && !saida ? 'green' : valor > 0 && saida ? 'red' : 'grey'
        }--text`"
      >
        {{ valor }}</span
      >
      <span class="ml-2">{{ nome }}</span>
    </div>
  </v-chip>
</template>

<script>
export default {
  props: {
    saida: { require: false, default: false, type: Boolean },
    nome: { require: false, type: String },
    valor: { require: false, type: Number },
    porcentagem: { require: false, type: String },
  },
};
</script>
