<template>
        <!-- <AppAlerta :snack="snack" /> -->                
        <div style="width: 100%; height: 100%; background-color: gray;">
            <center>
                <h1 style="color: white">RODA DA SAÚDE</h1>
            </center>
        <div>  
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        v-model="nome"
                        label="Nome"
                        color="orange"
                        outlined>
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                                v-model="email"
                                label="E-mail"
                                color="#FF562B"
                                outlined>
                    </v-text-field>                    
                </v-col>
            </v-row>          
        </div>
                <div class="pieContainer">
                    <div class="pieCenter"></div>
                    <div class="pieBackground">
                    </div>                    

                    <div id="pieSlice1" class="hold" v-on:click="abre()">
                        <div class="pie" v-on:click="abre()"></div>
                    </div>
                    <div id="pieSlice1_content">                    
                        <div>
                            <v-img src="../assets/coracao.png" v-on:click="abre('Emocional')" style="z-index:1;">                                
                            </v-img>
                        </div>
                    </div>
                    <div id="pieSlice2" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice2_content">                
                        <div>
                            <v-img src="../assets/halteres.png" v-on:click="abre('Exercício')" style="z-index:1;"></v-img>
                            
                        </div>
                    </div>
                    <div id="pieSlice3" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice3_content">
                        <div>
                            <v-img src="../assets/moeda.png" v-on:click="abre('Financeiro')" style="z-index:1;"></v-img>
                        </div>
                    </div>
                    <div id="pieSlice4" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice4_content">                
                        <div>
                            <v-img src="../assets/mala.png" v-on:click="abre('Trabalho')" style="z-index:1;"></v-img>
                        </div>
                    </div>
                    <div id="pieSlice5" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice5_content">
                        <div>
                            <v-img src="../assets/relacionamento.png" v-on:click="abre('Relacionamento')" style="z-index:1;"></v-img>
                        </div>
                    </div>
                    <div id="pieSlice6" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice6_content">
                        <div>
                            <v-img src="../assets/argolas.png" v-on:click="abre('Vida Sexual')" style="z-index:1;"></v-img>
                        </div>
                    </div>
                    <div id="pieSlice7" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice7_content">
                        <div>
                            <v-img src="../assets/quebra-cabeca.png" v-on:click="abre('Hobby')" style="z-index:1;"></v-img>
                        </div>
                    </div>
                    <div id="pieSlice8" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice8_content">
                        <div>
                            <v-img src="../assets/lua.png" v-on:click="abre('Sono')" style="z-index:1;"></v-img>
                        </div>
                    </div>
                    <div id="pieSlice9" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice9_content">
                        <div>
                            <v-img src="../assets/orar.png" v-on:click="abre('Espiritual')" style="z-index:1;"></v-img>
                        </div>
                    </div>
                    <div id="pieSlice10" class="hold">
                        <div class="pie"></div>
                    </div>
                    <div id="pieSlice10_content">                
                        <div>
                            <v-img src="../assets/prato.png" v-on:click="abre('Alimentação')" style="z-index:1;"></v-img>
                        </div>
                    </div>                    
                </div>
                <br/>
                    <center>
                    <h2>Média da Saúde</h2>
                    <div style="border-style: solid; border-width: thin; width: 100px; height: 60px;">
                        <h1>{{ soma }}</h1></div>
                    </center>
                <center>
                <div style="padding-top: 10px">
                    <v-simple-table style="width: 90%">                        
                        <tbody>
                            <tr>
                                <td>Emocional</td>
                                <td>{{ emocional }}</td>
                            </tr>
                            <tr>
                                <td>Exercício</td>
                                <td>{{ exercicio }}</td>
                            </tr>
                            <tr>
                                <td>Financeiro</td>
                                <td>{{ financeiro }}</td>
                            </tr>
                            <tr>
                                <td>Trabalho</td>
                                <td>{{ trabalho }}</td>
                            </tr>
                            <tr>
                                <td>Relacionamento</td>
                                <td>{{ relacionamento }}</td>
                            </tr>
                            <tr>
                                <td>Vida Sexual</td>
                                <td>{{ vida_sexual }}</td>
                            </tr>
                            <tr>
                                <td>Hobby</td>
                                <td>{{ hobby }}</td>
                            </tr>
                            <tr>
                                <td>Sono</td>
                                <td>{{ sono }}</td>
                            </tr>
                            <tr>
                                <td>Espiritual</td>
                                <td>{{ espiritual }}</td>
                            </tr>
                            <tr>
                                <td>Alimentação</td>
                                <td>{{ alimentacao }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <!-- <v-btn @click="enviaEmail()" :disabled="!desabititado">Calcular</v-btn> -->                    
                </div>
            </center>                                
        <v-dialog
        v-model="dialog"
        style="z-index: 999"
        width="200px"
      >
        <v-card>
          <v-card-title>
            <span>{{ titulo }}</span>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              left
            >              
              <v-list>
                <v-list-item>
                  <v-list-item-title>{{ titulo }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>          
          <v-card-subtitle>
            <div style="height: 20px;"></div>
          </v-card-subtitle>
          <v-card-text>
            <v-text-field v-model="valor" type="number" :maxlength="2" outlined></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="salva_parametro(titulo, valor)"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>      
    </div>
</template>

<script>
import _usuario from '../services/usuario/usuario-servico.js'

export default {    
    components: {            
    },
    data () {
        return {
            sabotadores: [
                'Emocional',
                'Exercício',
                'Financeiro',
                'Trabalho',
                'Relacionamento',
                'Vida Sexual',
                'Hobby',
                'Sono',
                'Espiritual',
                'Alimentação'
            ], 
            dialog: false,
            emocional: '',
            exercicio: '',
            financeiro: '',
            trabalho: '',
            relacionamento: '',
            vida_sexual: '',
            hobby: '',
            sono: '',
            espiritual: '',
            alimentacao: '',
            valor: '',
            soma: '',
            desabititado: true,
            nome: '',
            email: '',
            usuario: [],
        }
    },
    methods: {
        abre(titulo){
            //alert('oi')
            this.dialog = true;
            this.titulo = titulo;
        },
        salva_parametro(titulo, valor){
            switch (titulo) {
            case 'Emocional':
                this.emocional = valor
                break;
            case 'Exercício':
                this.exercicio = valor
                break;
            case 'Financeiro':
                this.financeiro = valor
                break;
            case 'Trabalho':
                this.trabalho = valor
                break;
            case 'Relacionamento':
                this.relacionamento = valor
                break;
            case 'Vida Sexual':
                this.vida_sexual = valor
                break;
            case 'Hobby':
                this.hobby = valor
                break;
            case 'Sono':
                this.sono = valor
                break;
            case 'Espiritual':
                this.espiritual = valor
                break;
            case 'Alimentação':
                this.alimentacao = valor
                break;
        }
        this.valor = ''
        this.dialog = false
        if (this.emocional.length > 0  &&
            this.exercicio.length > 0  &&
            this.financeiro.length > 0  &&
            this.trabalho.length > 0  &&
            this.relacionamento.length > 0  &&
            this.vida_sexual.length > 0  &&
            this.hobby.length > 0  &&
            this.sono.length > 0  &&
            this.espiritual.length > 0  &&
            this.alimentacao.length > 0)
            {
                //this.desabititado = true
                this.calcula()
            }
        },
        calcula()
        {         
            var calculo = parseInt(this.emocional) + 
            parseInt(this.exercicio) + 
            parseInt(this.financeiro) +
            parseInt(this.trabalho) +
            parseInt(this.relacionamento) +
            parseInt(this.vida_sexual) +
            parseInt(this.hobby) +
            parseInt(this.sono) +
            parseInt(this.espiritual) +
            parseInt(this.alimentacao)
            console.log(calculo / 10)
            this.soma = calculo / 10
            this.salvarDados()
        },
        salvarDados()        
        {
            this.usuario = {                
                nome: this.nome,
                email: this.email,
                roda_saude: this.emocional + '|' +
                this.exercicio + '|' +
                this.financeiro + '|' +
                this.trabalho + '|' +
                this.relacionamento + '|' +
                this.vida_sexual + '|' +
                this.hobby + '|' + 
                this.sono + '|' +
                this.espiritual + '|' +
                this.alimentacao
            } 

            _usuario.cadastrarUsuarioExterno(this.usuario).then(() => {
                alert('Dados Salvos')
            })
    },           
    }
}
</script>



<style>

.pieContainer {
    padding-top: 10px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pieBackground {
    background-color: grey;
    position: absolute;          
    width: 350px;
    height: 350px;
    -moz-border-radius: 175px;
    -webkit-border-radius: 175px;
    -o-border-radius: 175px;
    border-radius: 175px;
    -moz-box-shadow: -1px 1px 3px #000;
    -webkit-box-shadow: -1px 1px 3px #000;
    -o-box-shadow: -1px 1px 3px #000;
    box-shadow: -1px 1px 3px #000;
}
.pie {
    position: absolute;
    width: 350px;
    height: 350px;
    -moz-border-radius: 175px;
    -webkit-border-radius: 175px;
    -o-border-radius: 175px;
    border-radius: 175px;
    clip: rect(0px, 175px, 350px, 0px);
}
.pieCenter {
    width: 50px;
    height: 50px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    background-color: white;
    -webkit-transform:rotate(0deg);
    -moz-transform:rotate(0deg);
    -o-transform:rotate(0deg);
    transform:rotate(0deg);
    z-index: 998;
}       
.hold {
    position: absolute;
    width: 350px;
    height: 350px;
    -moz-border-radius: 175px;
    -webkit-border-radius: 175px;
    -o-border-radius: 175px;
    border-radius: 175px;
    clip: rect(0px, 350px, 350px, 175px);
}
#pieSlice1 {                         
    -webkit-transform:rotate(-26deg);
    -moz-transform:rotate(-26deg);
    -o-transform:rotate(-26deg);
    transform:rotate(-26deg);
}        
#pieSlice1 .pie {
    background-color: #be4298;
    -webkit-transform:rotate(51deg);
    -moz-transform:rotate(51deg);
    -o-transform:rotate(51deg);
    transform:rotate(51deg);
}
#pieSlice1_content {    
    position: absolute;
    margin-bottom: 290px;
    width: 50px;
    height: 50px;
    /* margin-left: -60px; */
}
#pieSlice2 {
    -webkit-transform:rotate(25deg);
    -moz-transform:rotate(25deg);
    -o-transform:rotate(25deg);
    transform:rotate(25deg);
}
#pieSlice2 .pie {
    background-color: #8e161a;
    -webkit-transform:rotate(32deg);
    -moz-transform:rotate(32deg);
    -o-transform:rotate(32deg);
    transform:rotate(32deg);
}
#pieSlice2_content {
    position: absolute;
    margin-bottom: 210px;
    width: 50px;
    height: 50px;              
    margin-left: 190px;
}
#pieSlice3 {
    -webkit-transform:rotate(57deg);
    -moz-transform:rotate(57deg);
    -o-transform:rotate(57deg);
    transform:rotate(57deg);
}
#pieSlice3 .pie {
    background-color: #e75f00;
    -webkit-transform:rotate(32deg);
    -moz-transform:rotate(32deg);
    -o-transform:rotate(32deg);
    transform:rotate(32deg);
}
#pieSlice3_content {
    position: absolute;
    margin-bottom: 65px;
    width: 50px;
    height: 50px;
    margin-left: 265px;

}
#pieSlice4 {
    -webkit-transform:rotate(89deg);
    -moz-transform:rotate(89deg);
    -o-transform:rotate(89deg);
    transform:rotate(89deg);
}
#pieSlice4 .pie {
    background-color: #52a071;
    -webkit-transform:rotate(32deg);
    -moz-transform:rotate(32deg);
    -o-transform:rotate(32deg);
    transform:rotate(32deg);
}
#pieSlice4_content {
    position: absolute;
    margin-top: 70px;
    width: 50px;
    height: 50px;
    margin-left: 260px;
}
#pieSlice5 {
    -webkit-transform:rotate(127deg);
    -moz-transform:rotate(127deg);
    -o-transform:rotate(127deg);
    transform:rotate(121deg);
}
#pieSlice5 .pie {
    background-color: #4ea399;
    -webkit-transform:rotate(32deg);
    -moz-transform:rotate(32deg);
    -o-transform:rotate(32deg);
    transform:rotate(32deg);
}
#pieSlice5_content {
    position: absolute;
    margin-top: 220px;
    width: 50px;
    height: 50px;
    margin-left: 190px;
}
#pieSlice6 {
    -webkit-transform:rotate(250deg);
    -moz-transform:rotate(250deg);
    -o-transform:rotate(250deg);
    transform:rotate(153deg);
}
#pieSlice6 .pie {
    background-color: #284293;
    -webkit-transform:rotate(50deg);
    -moz-transform:rotate(50deg);
    -o-transform:rotate(50deg);
    transform:rotate(50deg);
}
#pieSlice6_content {
    position: absolute;
    margin-top: 290px;
    width: 50px;
    height: 50px;
    margin-left: 5px;
}
#pieSlice7 {
    -webkit-transform:rotate(203deg);
    -moz-transform:rotate(203deg);
    -o-transform:rotate(203deg);
    transform:rotate(203deg);
}
#pieSlice7 .pie {
    background-color: #8c0b7b;
    -webkit-transform:rotate(33deg);
    -moz-transform:rotate(33deg);
    -o-transform:rotate(33deg);
    transform:rotate(33deg);
}
#pieSlice7_content {
    position: absolute;
    margin-top: 220px;
    width: 50px;
    height: 50px;
    margin-right: 180px;
}
#pieSlice8 {
    -webkit-transform:rotate(236deg);
    -moz-transform:rotate(236deg);
    -o-transform:rotate(236deg);
    transform:rotate(236deg);
}
#pieSlice8 .pie {
    background-color: #e3df1b;
    -webkit-transform:rotate(33deg);
    -moz-transform:rotate(33deg);
    -o-transform:rotate(33deg);
    transform:rotate(33deg);
}
#pieSlice8_content {
    position: absolute;
    margin-top: 75px;
    width: 50px;
    height: 50px;
    margin-right: 260px;
}
#pieSlice9 {
    -webkit-transform:rotate(267deg);
    -moz-transform:rotate(267deg);
    -o-transform:rotate(267deg);
    transform:rotate(268deg);
}
#pieSlice9 .pie {
    background-color: #c1bec0;
    -webkit-transform:rotate(33deg);
    -moz-transform:rotate(33deg);
    -o-transform:rotate(33deg);
    transform:rotate(33deg);
}
#pieSlice9_content {
    position: absolute;
    margin-bottom: 55px;
    width: 50px;
    height: 50px;
    margin-right: 270px;
}
#pieSlice10 {
    -webkit-transform:rotate(301deg);
    -moz-transform:rotate(301deg);
    -o-transform:rotate(301deg);
    transform:rotate(301deg);
}
#pieSlice10 .pie {
    background-color: #03a7bd;          
    -webkit-transform:rotate(33deg);
    -moz-transform:rotate(33deg);
    -o-transform:rotate(33deg);
    transform:rotate(33deg);
}
#pieSlice10_content {
    position: absolute;
    margin-bottom: 205px;
    width: 50px;
    height: 50px;
    margin-right: 195px;
}
</style>