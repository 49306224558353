<template>
  <v-container fluid>
    <AppConfirma :dialogs="dialogs" @resposta="getResposta"></AppConfirma>
    <AppBar titulo="" :destino="origem" />
    <v-toolbar color="orange" dense>
      <v-toolbar-title>Alunos</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <br>
    <AppAlerta :snack="snack" />
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <ValidationObserver ref="form" #default="{ handleSubmit }">
            <v-form
              @keyup.enter="handleSubmit(salvar)"
              @submit.prevent="handleSubmit(salvar)"
            >
              <v-card-text>
                <v-row>
                  <v-col col="6" md="6" xl="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="aluno"
                    >
                      <v-combobox
                        v-model="pessoa_aluno"
                        :items="usuarios"
                        label="Aluno"
                        :error-messages="errors[0]"
                        item-text="nome"
                        item-value="id"
                        outlined
                        :disabled="pessoaDesativada"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col col="6" md="6" xl="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="treinador"
                    >
                      <v-select
                        v-model="treinador"
                        :items="treinadores"
                        label="Treinador"
                        :error-messages="errors[0]"
                        item-text="nome"
                        item-value="id"
                        outlined
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="6" md="6" xl="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="Unidade"
                    >
                      <v-select
                        v-model="academia"
                        :items="academias"
                        label="Unidade"
                        :error-messages="errors[0]"
                        item-text="nome"
                        item-value="id"
                        outlined
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col col="3" md="3" xl="3">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="data incial"
                    >
                      <v-text-field
                        v-model="data_inicial"
                        label="Data Inicial"
                        color="#FF562B"
                        :error-messages="errors[0]"
                        outlined
                        v-mask="'##/##/####'"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col col="3" md="3" xl="3">
                    <v-text-field
                      v-model="data_final"
                      label="Data Final"
                      color="#FF562B"
                      outlined
                      v-mask="'##/##/####'"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-row>
                  <v-col col="12" md="4" xl="4">
                    <v-btn @click="cancelar" color="grey" width="100%" dark
                      ><v-icon class="mr-2">mdi-arrow-left</v-icon>Cancelar</v-btn
                    >
                  </v-col>
                  <v-col col="12" md="4" xl="4">
                    <v-btn
                      color="red"
                      width="100%"
                      dark
                      @click="excluir()"
                      :disabled="isDisabled"
                      >Excluir <v-icon class="ml-2">mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col col="12" md="4" xl="4">
                    <v-btn color="#FF562B" width="100%" dark type="submit"
                      >Salvar <v-icon class="ml-2">mdi-content-save</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import _usuario from "../../services/usuario/usuario-servico.js";
import _treinador from "../../services/treinador/treinador-servico.js";
import _aluno from "../../services/aluno/aluno-servico.js";
import _utils from "../../utils/utils.js";
import _academia from "../../services/academia/academia-servico.js";
import AppAlerta from "../../components/AppAlerta.vue";
import AppBar from "../../components/AppBar.vue";
import AppConfirma from "../../components/AppConfirma.vue";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AppAlerta,
    AppBar,
    AppConfirma,
  },

  data() {
    return {
      dialogs: {
        dialog: false,
        titulo: "",
      },
      snack: {
        snackbar: false,
        texto: "",
        cor: "",
      },
      id: 0,
      usuarios: [],
      treinadores: [],
      aluno: [],
      academias: [],
      academia: "",
      pessoa: "",      
      pessoa_aluno: null,
      treinador: "",
      data_inicial: "",
      data_final: "",
      tab: null,
      isDisabled: true,
      pessoaDesativada: false,
      origem: "alunos",
    };
  },

  mounted() {
    _usuario.listarUsuarios().then((response) => {
      response.forEach((usuario) => {
        this.usuarios.push({
          id: usuario.id,
          nome: usuario.nome,
        });
      });
    });

    _treinador.listartreinadores().then((response) => {
      response.forEach((treinador) => {
        this.treinadores.push({
          id: treinador.id,
          nome: treinador.nome,
        });
      });
    });

    _academia.listarAcademias().then((response) => {
      response.forEach((academia) => {
        this.academias.push({
          id: academia.id,
          nome: academia.nome,
        });
      });
    });

    if (this.$route.params.id != null) {
      if (this.$route.params.origem == "usuarios") {
        if (this.$route.params.tipo == "cadastrar") {
          this.pessoa = this.$route.params.id;
          this.isDisabled = true;
        } else {
          this.origem = "usuarios";
          this.buscar(this.$route.params.id);
          this.id = this.$route.params.id;
        }
      } else {
        this.buscar(this.$route.params.id);
        this.id = this.$route.params.id;
      }
      this.pessoaDesativada = true
    }
  },

  methods: {
    buscar(id) {
      _aluno.buscarAluno(id).then((response) => {
        this.aluno = response;
        this.pessoa = this.aluno.usuario_id;
        this.pessoa_aluno = { id: this.aluno.usuario_id, nome: this.aluno.nome }
        //this.pessoa_aluno = this.aluno.usuario_id;
        this.treinador = this.aluno.treinador_id;
        this.academia = this.aluno.academia_id;
        this.data_inicial = _utils.formatarDataTela(this.aluno.data_inicial);
        if (this.aluno.data_final != null)
          this.data_final = _utils.formatarDataTela(this.aluno.data_final);
        this.isDisabled = false;
      });
    },

    salvar() {      
      if (this.pessoa == this.treinador) {
        console.log("erro");
      } else {
        this.aluno = {
          usuario_id: this.pessoa_aluno.id,
          treinador_id: this.treinador,
          academia_id: this.academia,
          data_acesso: null,
          acesso: false,
          data_inicial: _utils.formatarData(this.data_inicial),        
          data_final:
            this.data_final == "" ? null : _utils.formatarData(this.data_final),
        };
        if (this.id == 0) {
          this.cadastrar();
        } else {
          this.atualizar();
        }
      }
    },
    cadastrar() {
      _aluno
        .cadastrarAluno(this.aluno)
        .then((response) => {
          this.id = response.id;
          this.snack.texto = "Aluno cadastrado com sucesso!";
          this.snack.snackbar = true;
          setTimeout(() => {
            this.redireciona();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    atualizar() {
      console.log("atualizar");
      _aluno.atualizarAluno(this.id, this.aluno).then(() => {
        this.snack.texto = "Aluno atualizado com sucesso!";
        this.snack.snackbar = true;
        setTimeout(() => {
          this.redireciona();
        }, 1000);
      });
    },
    excluir() {
      this.dialogs.dialog = true;
      this.dialogs.titulo = "Excluir Aluno";
      this.dialogs.mensagem = "Deseja excluir o aluno?";
    },
    getResposta(value) {
      this.resposta = value;
      if (this.resposta == true) {
        _aluno.deletarAluno(this.id).then(() => {
          this.snack.texto = "Aluno excluído com sucesso!";
          this.snack.snackbar = true;
          setTimeout(() => {
            this.redireciona();
          }, 1000);
        });
      }
    },
    cancelar() {
      this.redireciona();
    },

    redireciona() {
      if (this.origem == "usuarios") this.rota("usuarios");
      else this.rota("alunos");
    },

    rota(rota) {
      this.$router.replace({ name: rota });
    },
  },
};
</script>