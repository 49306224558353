<template>
    <v-app
        id="icaro-adm"
        translate="no"
        class="notranslate">
        <v-main>
            <v-container fluid>
                <v-slide-y-transition mode="out-in">
                    <v-layout column>
                        <router-view />
                    </v-layout>
                </v-slide-y-transition>
            </v-container>
        </v-main>
        <app-menu v-if="!VerificarLogin() && !VerificaMenu()"></app-menu>
        <app-menu-connect v-if="!VerificarLogin() && VerificaMenu()"></app-menu-connect>
    </v-app>
</template>

<script>
import AppMenu from "./components/AppMenu.vue"
import AppMenuConnect from "./components/AppMenuConnect.vue";

export default {
  components: {
    AppMenu,
    AppMenuConnect,
  },
  props: ['prd'],  
  mounted() {
   },
  data() {
    return {    
        produto: this.prd
    };
  },
  methods:{
    VerificarLogin() {
     return this.$route.name === 'login'
    },
    VerificaMenu()
    {
        if (this.$route.name && this.$route.name.includes('connect'))
            return true;
        else
            return false
    }
  }
  
};
</script>

<style lang="scss">
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
} */

/**** MENSAGEM COPIAR PARA A AREA DE TRANSFERENCIA *****/
.mensagem-copiar {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 12px;
    position: fixed;
    z-index: 999;
    bottom: 30px;
    font-size: 17px;
    border-radius: 8px;
    right: 50px;

    &.mostrar {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    @media screen and (max-width: 700px) {
        right: 30px;
    }
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

</style>