import api from "../api/icaro"

const icaro = {
  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarAcademias() {
    return new Promise((resolve, reject) => {
      api
        .get(`/academia/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  }    
}

export default icaro