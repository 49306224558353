import api from "../api/icaro"

const icaro = {
    /**
     *  
     * @returns {Promise} response.data ou erro
     * */
    buscarProdutosTreinadores(usuario_id) {
        return new Promise((resolve, reject) => {
            api
                .get(`/agenda/produtos/treinadores/${usuario_id}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },

    /**
     *  
     * @returns {Promise} response.data ou erro
     * */
    buscarAgenda(usuario_id) {
        return new Promise((resolve, reject) => {
            api
                .get(`/agenda/produtos/${usuario_id}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
    
    
    /**
     *  
     * @returns {Promise} response.data ou erro
     * */
    buscarAgendaAluno(aluno) {
        return new Promise((resolve, reject) => {
            api
                .get(`/agenda/${aluno}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },

    /**
     * 
     * @param {Object} agenda 
     **/
    salvarAgenda(agenda) {
        return new Promise((resolve, reject) => {
            api
                .post("/agenda/", agenda)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },

    /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
    atualizarAgenda(id, agenda) {
        return new Promise((resolve, reject) => {
        api
            .put(`/agenda/${id}`, agenda)
            .then((response) => {
            resolve(response.data)
            })
            .catch((erro) => {
            reject(erro)
            })
        })
    },

    /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
    excluirAgenda(id) {
        return new Promise((resolve, reject) => {
        console.log(id)
        api
            .delete(`/agenda/${id}`)
            .then((response) => {
            resolve(response.data)
            })
            .catch((erro) => {
            reject(erro)
            })
        })
    }
}

export default icaro