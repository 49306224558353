import api from "../api/icaro"

const dre = {
  
  buscar(dre_id) {
    return new Promise((resolve, reject) => {
      api
        .get(`dre/${dre_id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(erro => {
          reject(erro)
        })
    })
  },

  listar() {
    return new Promise((resolve, reject) => {
      api
        .get("dre/")
        .then(response => {
          resolve(response.data)
        })
        .catch(erro => {
          reject(erro)
        })
    })
  },

  salvar(dre) {
    return new Promise((resolve, reject) => {
      api
        .post(`/dre/`, dre)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

}

export default dre
