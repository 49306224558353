<template>
  <v-container fluid>
    <AppBar titulo="Alunos" destino="treinadorinicial"/>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>        
          <v-card-title>
            Filtros
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field label="Nome" v-model="nome"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-checkbox label="Ativos" v-model="ativos" input-value="true" value></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox label="Fidelidade" v-model="fidelidade" input-value="true" value></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="#FF562B" dark v-on:click="listarAlunos()">Buscar</v-btn>
            <!-- <v-btn color="#ccc" dark>Limpar</v-btn> -->
            <v-btn class="ml-auto" color="#FF562B" dark v-on:click="rota('cadastroalunos')">Cadastrar</v-btn> 
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>                    
          <v-list>            
                <v-list-item
                    v-for="(item, index) in alunos"
                    :key="index"                    
                >
                    <v-list-item-avatar>
                      <v-avatar>
                        <v-img src="https://i.pravatar.cc/300">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="orange lighten-2"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.nome }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.idade }} anos</v-list-item-subtitle>
                        <v-list-item-action-text><i>pagamentos</i></v-list-item-action-text>
                    </v-list-item-content>
                    <v-list-item-action>
                      <template>
                        <v-icon
                          medium
                          class="mr-4"
                          @click="editarAluno(item)"
                        >
                          mdi-pencil-outline
                        </v-icon>
                      </template>
                    </v-list-item-action>
                    <v-list-item-action>
                      <template>                      
                          <v-icon
                            medium
                            class="mr-4"
                            @click="editarAgenda(item)"
                          >
                            mdi-calendar-clock-outline
                          </v-icon>
                      </template>                                          
                    </v-list-item-action>
                    <v-list-item-action>
                        <template>                      
                            <v-icon
                              medium
                              @click="editarAnamnese(item)"
                            >
                              mdi-account-edit-outline
                            </v-icon>            
                          </template>                                          
                        </v-list-item-action>
                    <v-list-item-action>
                    <template>                      
                        <v-icon
                          medium
                          @click="editarAvaliacao(item)"
                        >
                          mdi-clipboard-check-outline
                        </v-icon>
                      </template>                                          
                    </v-list-item-action>
                </v-list-item>
                <v-divider inset></v-divider>
            </v-list>                        
        </v-card>
      </v-col>
    </v-row> -->
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <v-data-table
              :headers="headers"
              :items="alunos"
              :items-per-page="10"
              class="elevation-1"
              :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-arrow-left',
                  nextIcon: 'mdi-arrow-right',
                    'items-per-page-text':'Itens por página'
              }"
            >
            <template v-slot:[`item.nome`]="{ item }">
              <v-avatar size="40px">
                        <!-- <v-img src="https://i.pravatar.cc/300"> -->                          
                          <v-img :src="`data:image/png;base64,${item.foto}`">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="orange lighten-2"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
              </v-avatar>
              <span> {{ item.nome }}</span>
            </template>
            <template v-slot:[`item.cadastro`]="{ item }">
                <v-icon
                  medium
                  class="mr-2"
                  @click="editarAluno(item)"
                >
                  mdi-pencil-outline
                </v-icon>
            </template>
            <template v-slot:[`item.contrato`]="{ item }">
                <v-icon
                  medium
                  class="mr-2"
                  @click="editarContrato(item)"
                >
                  mdi-file-sign
                </v-icon>
            </template>
            <template v-slot:[`item.agenda`]="{ item }">
                <v-icon
                  medium
                  class="mr-2"
                  @click="editarAgenda(item)"
                >
                mdi-calendar-clock-outline
                </v-icon>
            </template>
            <template v-slot:[`item.anamnese`]="{ item }">
                <v-icon
                  medium
                  style="align: center"
                  @click="editarAnamnese(item)"
                >
                  mdi-account-edit-outline
                </v-icon>
            </template>
            <template v-slot:[`item.avaliacao`]="{ item }">
                <v-icon
                  medium
                  @click="editarAvaliacao(item)"
                >
                  mdi-clipboard-check-outline
                </v-icon>
            </template>
            <template v-slot:no-data>
              <v-spacer>
                <div>
                  <br/>
                </div>
              </v-spacer>
              <v-alert
                :value="true"
                type="info"
                icon="mdi-alert-circle-outline"
                color="warning"
                dark
              >
                Nenhum aluno encontrado.
              </v-alert>      
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import _alunos from '../../services/aluno/aluno-servico.js'
import AppBar from '../../components/AppBar.vue'
export default {
  components: {
    AppBar
  },
  
  props: {
    
  },

  data () {
      return {
        headers: [
          // { text: 'Código', value: 'id' },
          { text: 'Nome', align: 'start',value: 'nome' },
          { text: 'Data Inicial', value: 'data_inicial' },
          { text: 'Data Final', value: 'data_final' },
          { text: 'Fidelidade - Início', value: 'contrato_ini' },
          { text: 'Fidelidade - Final', value: 'contrato_fin' },
          { text: 'Cadastro', value: 'cadastro', align: 'center', sortable: false },
          { text: 'Contrato', value: 'contrato', align: 'center', sortable: false },
          { text: 'Agenda', value: 'agenda', align: 'center', sortable: false },
          { text: 'Anamense', value: 'anamnese', align: 'center', sortable: false },
          { text: 'Avaliação', value: 'avaliacao', align: 'center', sortable: false },
        ],
        alunos: [],
        nome: '',
        relacionamento: 0,
        ativos: true,
        fidelidade: false,
      }
    },

  created() {
    // this.$vuetify.theme.dark = parseInt(this.$route.params.tema) === 1
  },

  mounted() {
    if(localStorage.dados) {        
            this.dados = JSON.parse(localStorage.dados)
            this.username = this.dados['nome']
            this.img = this.dados['foto']
            this.relacionamento = this.dados['relacionamento_id']
            this.listarAlunos()
        }
    else {
      this.$router.replace({name:'login'})
    }
  },

  methods: {    
    listarAlunos() {
      if (this.nome)
      {
        console.log(this.nome)
        _alunos.buscarAlunoTreinadorNome(this.dados.relacionamento_id, this.nome).then(response =>
        {          
          if (this.ativos == true)
          {
            this.alunos = response.filter(function (el){
              return el.data_final == null
            });
          }
          else{
            this.alunos = response
          }
        })
      }
      else
      {
        _alunos.listarAlunosTreinador(this.dados.relacionamento_id).then(response => {
          this.alunos = response          
          if (this.ativos == true)
          {
            this.alunos = this.alunos.filter(function (el){
              return el.data_final == null
            });
          }
          if (this.fidelidade == true)
          {
            this.alunos = this.alunos.filter(function (el){
              return el.contrato_fin >= Date.now
            });
          }          
        })    
      }
    },    
    editarAluno(aluno) {
      this.$router.push({name:'cadastroalunos', params: {id: aluno.id}})
    },
    editarContrato(aluno) {
      this.$router.push({name:'contrato', component: aluno, params: {aluno: aluno}})
    },
    editarAgenda(aluno) {
      this.$router.push({name:'agendaaluno', params: {aluno: aluno}}) 
    },
    editarAnamnese(aluno) {
      this.$router.push({name:'anamnese', params: {aluno: aluno}})
    },
    editarAvaliacao(aluno) {
      //this.$router.push({name:'avaliacao', params: {aluno: aluno}})
      this.$router.push({name:'lista-avaliacao', params: {aluno: aluno}})      
    },
    rota(rota) {        
        this.$router.replace({name:rota})
      },
    //...mapActions({
    //   mostrarCarregando: "carregando/mostrarCarregando",
    //   mostrarMensagem: "mensagem/mostrarMensagem",
    //}),
  },
}
</script>