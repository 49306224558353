<template>
    <div>
      <v-text-field
        v-model="campoConsulta"
        prepend-inner-icon="mdi-magnify"
        label="consultar"
        outlined
        dense
        single-line
        hide-details
        clearable
        class="mb-2"
      ></v-text-field>
      <v-data-table
        :headers="cabecalho"
        :items="notas"
        class="elevation-0"
        :search="campoConsulta"
      >
        <template v-slot:[`item.emissao`]="{ item }">
          <span>{{ formatarData(item.emissao) }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="RetornaEnumStatus(item.status).cor" v-bind="attrs" v-on="on">
            <small>{{ RetornaEnumStatus(item.status).descricao }}</small>
          </v-chip>
        </template>
        <template v-slot:[`item.link_xml`]="{  item }">
          <v-btn icon v-if="item.link_xml" @click="baixarArquivo(item.link_xml)">
            <v-icon>mdi-file-xml-box</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.link_pdf`]="{  item }">
          <v-btn icon v-if="item.link_pdf" @click="baixarArquivo(item.link_pdf)">
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </template>
  
  <script>
  import _statusNotaFiscal from '../../enums/status-nota-fiscal.js';

  export default {
    props: {
      notas: { required: false, type: Array, default: null },
    },
    data() {
      return {
        campoConsulta: null,
  
        cabecalho: [
          {
            text: "Descrição",
            align: "start",
            sortable: true,
            value: "descricao",
          },
          {
            text: "Usuário",
            align: "start",
            sortable: true,
            value: "usuario",
          },
          {
            text: "Valor",
            align: "center",
            sortable: true,
            value: "valor",
          },
          {
            text: "Emissão",
            align: "center",
            sortable: true,
            value: "emissao",
          },
          {
            text: "Situação",
            align: "center",
            sortable: false,
            value: "status",
          },
          {
            text: "XML",
            align: "center",
            sortable: false,
            value: "link_xml",
          },
          {
            text: "PDF",
            align: "center",
            sortable: false,
            value: "link_pdf",
          },
        ],
      };
    },
    methods: {      
      formatarData(data){
        return new Date(data).toLocaleDateString("pt-BR")
      },

      baixarArquivo(fileUrl) {
        const partsUrl = fileUrl.split("/");
        const nmArquivo = partsUrl[partsUrl.length - 1]
  
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', nmArquivo);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },

      RetornaEnumStatus(status) {
        return _statusNotaFiscal.find((F) => F.valor == status);
      },
    },
  };
  </script>