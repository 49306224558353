<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-row justify="space-around">
    <v-dialog      
      v-model="dialogs.dialog"
      persistent
      max-width="290"
    >
      <v-card>
      <v-toolbar dark color="grey lighten-3" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ dialogs.titulo }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text        
        class="pa-4 black--text"        
      >
      {{ dialogs.mensagem }}
      </v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="resposta(false)"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          class="body-2 font-weight-bold"
          outlined
          @click="resposta(true)"
          >Ok</v-btn
        >
      </v-card-actions>
    </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
    export default {
      props:["dialogs"],

      data () {
        return {
          dialog: false,
          return_dialog: false,
          retorno: []
        }
      },

      methods: {
        resposta(resposta) {
          // eslint-disable-next-line vue/no-mutating-props          
          this.dialogs.dialog = false;
          this.return_dialog = resposta;
          this.retorno = [this.dialogs.tipo, this.return_dialog]
          //this.tipo = tipo;
          this.$emit('resposta', this.retorno);
        },
 
      }
    }
  </script>